import styled from 'styled-components';
import {ImageFormGeneral} from './ImageFormGeneral';
import {ImageUpload} from './ImageUpload';

export const FormNormalImage = ({onChange, value, ...rest}) => {
  return (
    <ImageFormGeneral onChange={onChange} value={value} {...rest}>
      <FormNormalImage_ />
    </ImageFormGeneral>
  );
};

const NormalImage = styled.img`
  object-fit: contain;
  aspect-ratio: 1;
  border-radius: 10px;

  @media screen and (min-width: 768px) {
    width: 100%;
  }
  img:not([src]) {
    border: none;
  }
`;

const FormNormalImage_ = ({handleFileChange, value, editIcon}) => {
  const uploadProps = {
    name: 'avatar',
    showUploadList: false,
    beforeUpload: (file) => {
      handleFileChange(file);
      return false;
    },
  };

  return (
    <>
      <NormalImage src={value?.src || value || null} />
      <ImageUpload {...uploadProps} accept='image/png, image/gif, image/jpeg'>
        {editIcon}
      </ImageUpload>
    </>
  );
};
