import {Tag} from 'antd';
import React from 'react';

function ETag({color, children}) {
  const isRGB = color.includes('rgb');
  const rgba = color.slice(0, -1)?.concat(',0.1)');

  return isRGB ? (
    <Tag
      style={{
        color: `${color}`,
        backgroundColor: rgba,
        fontWeight: 500,
        borderRadius: '24px',
      }}
      color={color}>
      {children}
    </Tag>
  ) : (
    <Tag color={color}>{children}</Tag>
  );
}

export default ETag;
