import {
  LIST_BOUGHT_URL,
  SERVICE_ADMIN_URL,
  SERVICE_USER_URL,
} from '../constants/api';
import axiosClient from './axiosClient';

export const serviceApi = {
  getList() {
    return axiosClient.get(SERVICE_USER_URL);
  },
  add(body) {
    return axiosClient.post(SERVICE_ADMIN_URL, body);
  },
  edit(id, body) {
    return axiosClient.put(SERVICE_ADMIN_URL + '/' + id, body);
  },
  delete(id) {
    return axiosClient.delete(SERVICE_ADMIN_URL + '/' + id);
  },
  move(arr) {
    return axiosClient.put(SERVICE_ADMIN_URL, {
      categoryIndexes: arr,
    });
  },
  getQuiz(id) {
    return axiosClient.get(SERVICE_USER_URL + '/' + id + '/quiz');
  },
  getListBought(params) {
    return axiosClient.get(LIST_BOUGHT_URL, {params});
  },
};
