import {permissions} from 'constants/auth';
import React from 'react';

const HistoryAdmin = React.lazy(() => import('./historyG/HistoryAdmin'));
const QuizList = React.lazy(() => import('./homeQuiz'));
const User = React.lazy(() => import('./user'));
const AddQuiz = React.lazy(() => import('./addQuiz'));
const Profile = React.lazy(() => import('./profileManage'));
const AllQuiz = React.lazy(() => import('./allQuiz'));
const MyQuiz = React.lazy(() => import('./myQuiz'));
const StartQuiz = React.lazy(() => import('./doQuiz'));
const CompleteQuiz = React.lazy(() => import('./completeQuiz'));
const HistoryAssignments = React.lazy(() => import('./history/Quiz'));
const HistoryScore = React.lazy(() =>
  import('./history/CalScore/HistoryScore'),
);
const ListProductsUser = React.lazy(() => import('./ListProductsUser'));
const ViewReport = React.lazy(() => import('./viewReport'));
const ServiceManage = React.lazy(() => import('./serviceManage'));
const ReportHistory = React.lazy(() => import('./reportHistory'));
const MoreSkills = React.lazy(() => import('./more/index'));
const Roles = React.lazy(() => import('./role'));
const TestNow = React.lazy(() => import('pages/TestNow'));

export const authorizedDiscStructure = [
  // Ẩn trang thông tin cá nhân
  // {
  //   permissionKey: permissions.profiles,
  //   path: '/my-profile',
  //   element: <UserProfile />,
  //   saveAsTriedLink: true,
  // },
  {
    permissionKey: permissions.dashboard,
    path: '/homeQuiz',
    element: <QuizList />,
    saveAsTriedLink: true,
  },
  {
    permissionKey: permissions.dashboard,
    path: '/quiz/start/:uuid/:questionComplex',
    element: <StartQuiz />,
    saveAsTriedLink: true,
  },
  {
    permissionKey: permissions.dashboard,
    path: '/homeQuiz/:userNameDoQuiz',
    element: <QuizList />,
  },
  {
    permissionKey: permissions.historyUser,
    path: '/list-products-quiz',
    element: <ListProductsUser />,
    saveAsTriedLink: true,
  },
  {
    permissionKey: permissions.users,
    path: '/user',
    element: <User />,
  },
  {
    permissionKey: permissions.profiles,
    path: '/profile',
    element: <Profile />,
  },
  {
    permissionKey: permissions.allQuiz,
    path: '/questions/all',
    element: <AllQuiz />,
  },

  {
    permissionKey: permissions.quizForm,
    path: '/questions/add',
    element: <AddQuiz isAdd />,
  },
  {
    permissionKey: permissions.quizForm,
    path: '/questions/:id',
    element: <AddQuiz />,
  },
  {
    permissionKey: permissions.services,
    path: '/service-manage',
    element: <ServiceManage />,
    saveAsTriedLink: true,
  },
  {
    permissionKey: permissions.myQuiz,
    path: '/questions/myquestions',
    element: <MyQuiz />,
    saveAsTriedLink: true,
  },
  {
    permissionKey: permissions.dashboard,
    path: 'quiz/complete/:id/:questionComplex',
    element: <CompleteQuiz />,
    saveAsTriedLink: true,
  },

  {
    permissionKey: permissions.historyAdmin,
    path: '/history/:id',
    element: <HistoryAssignments />,
    saveAsTriedLink: true,
  },
  {
    permissionKey: permissions.historyUser,
    path: '/history/:id',
    element: <HistoryScore />,
    saveAsTriedLink: true,
  },
  {
    permissionKey: permissions.historyUser,
    path: '/history/report',
    element: <ReportHistory />,
    saveAsTriedLink: true,
  },
  {
    permissionKey: permissions.historyUser,
    path: '/viewReport/:uuid',
    element: <ViewReport />,
    saveAsTriedLink: true,
  },

  {
    permissionKey: permissions.dashboard,
    path: '/services/skills/:uuid',
    element: <MoreSkills />,
    saveAsTriedLink: true,
  },
  {
    permissionKey: permissions.roles,
    path: '/roles',
    element: <Roles />,
  },
  {
    permissionKey: permissions.historyAdmin,
    path: '/history',
    element: <HistoryAdmin />,
  },
  {
    permissionKey: permissions.dashboard,
    path: '/route/test-now/:id',
    element: <TestNow />,
  },
  {
    permissionKey: permissions.dashboard,
    path: '/route/test-now',
    element: <TestNow />,
  },

  // {
  //   permissionKey: permissions.dashboard,
  //   path: '/pdf',
  //   element: <HandHollandResult />,
  // },
];
