import {PROFILE_URL} from '../constants/api';
import axiosClient from './axiosClient';

export const profileApi = {
  get(params) {
    return axiosClient.get(PROFILE_URL, {params});
  },
  edit(id, body) {
    return axiosClient.put(PROFILE_URL + '/' + id, body);
  },
  add(body) {
    return axiosClient.post(PROFILE_URL, body);
  },
  delete(id) {
    return axiosClient.delete(PROFILE_URL + '/' + id);
  },
};
