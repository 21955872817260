import {
  ALL_QUIZZES_FOR_ASSIGNMENT_FILTER,
  ALL_QUIZZES_FOR_REPORT_FILTER,
  ALL_QUIZ_URL,
  HOME_URL,
  MY_QUIZ_URL,
  URL_COMPLETE,
} from 'constants/api';
import axiosClient from './axiosClient';

export const quizApi = {
  getList(params) {
    return axiosClient.get(HOME_URL, {params});
  },
  getAllQuizList(params) {
    return axiosClient.get(ALL_QUIZ_URL, {params});
  },
  getAllQuizListForAssignmentFilter(params) {
    return axiosClient.get(ALL_QUIZZES_FOR_ASSIGNMENT_FILTER, {params});
  },
  getAllQuizListForReportFilter(params) {
    return axiosClient.get(ALL_QUIZZES_FOR_REPORT_FILTER, {params});
  },
  getMyQuizList(params) {
    return axiosClient.get(MY_QUIZ_URL, {params});
  },
  delete(id) {
    return axiosClient.delete(`${ALL_QUIZ_URL}/${id}`);
  },
  getResult(resultUuid, profileId) {
    const formData = new FormData();
    formData.append('resultUuid', resultUuid);
    formData.append('profileId', profileId);
    return axiosClient.post(URL_COMPLETE, formData, {
      headers: {
        'request-id': resultUuid,
      },
    });
  },
  // getUuid(id, userName) {
  //   const url = `${GET_UUID_ULR}/${id}/start`;
  //   return axiosClient.get(url, {
  //     params: {
  //       userName,
  //     },
  //   });
  // },
  // getQuiz(params) {
  //   const url = `${URL_QUIZ}/${params?.resultUuid}/show`;
  //   return axiosClient.get(url, {params: {...params, resultUuid: undefined}});
  // },
  // sendQuiz(id, payload) {
  //   const url = `${URL_QUIZ}/${id}/submit`;
  //   return axiosClient.post(url, payload);
  // },
};

export default quizApi;
