import './index.style.less';

const AppLogo = () => {
  return (
    <a className='app-logo' href='https://www.revica.org/'>
      <img src='/assets/images/logo.svg' alt='revica-logo' />
    </a>
  );
};

export default AppLogo;