import {ReactComponent as HocSinh} from '../../assets/icon/hocSinh.svg';
import {ReactComponent as NguoiDiLam} from '../../assets/icon/nguoiDiLam.svg';
import {ReactComponent as PhuHuynh} from '../../assets/icon/phu Huynh.svg';
import {ReactComponent as SinhVien} from '../../assets/icon/sinhVien.svg';
//API
export const FETCH_START = 'common/fetchStart';
export const FETCH_SUCCESS = 'common/fetchSuccess';
export const FETCH_ERROR = 'common/fetchError';
export const HIDE_MESSAGE = 'common/hideMessage';
export const SET_VISIBLE = 'common/setVisible';
//information
export const ICON_CUSTOMER_TYPES = [
  {icon: <HocSinh />, label: 'Học sinh', value: 'STUDENT'},
  {icon: <SinhVien />, label: 'Sinh viên', value: 'UNIVERSITY_STUDENT'},
  {icon: <NguoiDiLam />, label: 'Người đi làm', value: 'WORKER'},
  {icon: <PhuHuynh />, label: 'Phụ huynh', value: 'PARENTS'},
];
