/* eslint-disable react/prop-types */
import {Select} from 'components/tiny';
import {Select as SelectAntd} from 'antd';
import React from 'react';

const {Option} = SelectAntd;

export const GeneralSelect = ({data, displayKey, valueKey, ...rest}) => {
  return (
    <Select {...rest} allowClear>
      {data?.map((item) => {
        if (displayKey && valueKey) {
          return (
            <Option key={item?.[valueKey]} value={item?.[valueKey]}>
              {item?.[displayKey]}
            </Option>
          );
        }
        return null;
      })}
    </Select>
  );
};
