import {userApi} from 'api/user';

const {createAsyncThunk, createSlice} = require('@reduxjs/toolkit');

const initialState = {
  user: undefined,
  status: 'idle',
};
export const fetchUserInfo = createAsyncThunk(
  'user_info/fetchUserInfo',
  async () => {
    try {
      const {data} = await userApi.getInfoUser();
      return data;
    } catch (err) {
      console.log('err :', err);
    }
  },
);
const user_info = createSlice({
  name: 'user_info',
  initialState: initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserInfo.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUserInfo.fulfilled, (state, action) => {
        console.log('action :', action);
        state.user = action.payload;
        state.status = 'idle';
      });
  },
});
export const {setUser} = user_info.actions;
export default user_info.reducer;
