export const serviceButtonTypes = {
  INTERNAL: 'INTERNAL',
  EXTERNAL: 'EXTERNAL',
  DOQUIZ: 'DOQUIZ',
  LISTQUIZ: 'LISTQUIZ',
};

export const serviceButtonTypesForSelect = [
  {
    title: 'Link bên ngoài',
    value: serviceButtonTypes.EXTERNAL,
  },
  {
    title: 'Link nội bộ',
    value: serviceButtonTypes.INTERNAL,
  },
  {
    title: 'Làm trắc nghiệm',
    value: serviceButtonTypes.DOQUIZ,
  },
  {
    title: 'Danh sách trắc nghiệm',
    value: serviceButtonTypes.LISTQUIZ,
  },
];
