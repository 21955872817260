import {role} from 'constants/common';

export const authRole = {
  admin: [role.admin],
  user: [role.user],
};

export const ThemeStyle = {
  MODERN: 'modern',
  STANDARD: 'standard',
};

export const ThemeMode = {
  LIGHT: 'light',
  SEMI_DARK: 'semi-dark',
  DARK: 'dark',
};
export const LayoutType = {
  FULL_WIDTH: 'full-width',
  BOXED: 'boxed',
  FRAMED: 'framed',
};

export const MenuStyle = {
  DEFAULT: 'default',
  STANDARD: 'standard',
  ROUNDED: 'rounded',
  ROUNDED_REVERSE: 'rounded-reverse',
  CURVED_MENU: 'curved-menu',
};

export const NavStyle = {
  DEFAULT: 'default',
  MINI: 'mini',
  MINI_SIDEBAR_TOGGLE: 'mini-sidebar-toggle',
  STANDARD: 'standard',
  HEADER_USER: 'user-header',
  HEADER_USER_MINI: 'user-mini-header',
  DRAWER: 'drawer',
  BIT_BUCKET: 'bit-bucket',
  H_DEFAULT: 'h-default',
  HOR_HEADER_FIXED: 'hor-header-fixed',
  HOR_DARK_LAYOUT: 'hor-dark-layout',
};
export const FooterType = {
  FIXED: 'fixed',
  FLUID: 'fluid',
};
export const ThemeDirection = {
  RTL: 'rtl',
  LTR: 'ltr',
};