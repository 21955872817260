const {createSlice} = require('@reduxjs/toolkit');

const init = {
  isOpen: false,
  type: 'add',
  record: undefined,
};

const modalSlice = createSlice({
  name: 'modal',
  initialState: init,
  reducers: {
    setRecord(state, action) {
      state.record = action.payload;
    },
    setType(state, action) {
      state.type = action.payload;
    },
    openModal(state) {
      state.isOpen = true;
    },
    closeModal(state) {
      state.isOpen = false;
    },
  },
});

const modalReducer = modalSlice.reducer;
const modalActions = modalSlice.actions;

export {modalReducer, modalActions};
