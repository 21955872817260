import React, {useEffect, useState} from 'react';
import {Grid, Layout} from 'antd';
import AppSidebar from './AppSidebar';
import AppHeader from './AppHeader';
import './index.style.less';
import {AppContentView} from '../../../index';
import AppThemeSetting from '../../AppThemeSetting';
import AppFooter from '../components/AppFooter';
import AppScrollbar from '../../AppScrollbar';
import clsx from 'clsx';
import {FooterType} from '../../../../shared/constants/AppEnums';
import {isEmpty} from '../../../utility/GlobalHelper';
import {useLayoutContext} from '../../../utility/AppContextProvider/LayoutContextProvider';
import {useDispatch} from 'react-redux';
import {commonActions} from 'redux/slice/common';

const {useBreakpoint} = Grid;

const UserHeader = () => {
  const width = useBreakpoint();
  const {footer, footerType} = useLayoutContext();

  const dispatch = useDispatch();
  const onToggleSidebar = (value) => {
    dispatch(commonActions.setIsCollapsed(value));
  };

  useEffect(() => {
    if (!isEmpty(width)) {
      if (width.xl) {
        dispatch(commonActions.setIsCollapsed(false));
      } else {
        dispatch(commonActions.setIsCollapsed(true));
      }
    }
  }, [width]);

  return (
    <Layout
      className={clsx('app-layout-userHeader', {
        appMainFooter: footer && footerType === FooterType.FLUID,
        appMainFixedFooter: footer && footerType === FooterType.FIXED,
      })}>
      <AppSidebar />
      <Layout className='app-layout-userHeader-main'>
        <AppHeader onToggleSidebar={onToggleSidebar} />
        <AppScrollbar className='userHeader-main-scrollbar'>
          <AppContentView />
          <AppFooter />
        </AppScrollbar>
      </Layout>
      <AppThemeSetting />
    </Layout>
  );
};

export default React.memo(UserHeader);
