import {CONFIG_USER_URL} from '../constants/api';
import axiosClient from './axiosClient';

export const userConfigApi = {
  get() {
    return axiosClient.get(CONFIG_USER_URL);
  },
  edit(body) {
    return axiosClient.post(CONFIG_USER_URL, {configs: body});
  },
};
