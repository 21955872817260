import React from 'react';
import {Button} from 'antd';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyleButton = styled(Button)`
  background-image: linear-gradient(to right, #ce9b25, #efc878);
  color: #fff;
  border: none;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  /* box-shadow: 0 4px 8px 0 rgba(237, 198, 115, 0.6); */

  &:hover {
    background-position: 100% 0;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }

  &:focus {
    border-color: #edc067;
  }
`;
export default function ButtonGradient({children, style, ...rest}) {
  return (
    <StyleButton type='primary' {...rest} style={style}>
      {children}
    </StyleButton>
  );
}

ButtonGradient.propTypes = {
  children: PropTypes.string,
  style: PropTypes.string,
};
