import {Form, Grid, Modal as ModalAntd} from 'antd';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {modalActions} from '../slice';
import styled from 'styled-components';
import {breakPoints} from 'global/responsive';

const {useBreakpoint} = Grid;

const Modal = styled(ModalAntd)`
  @media ${breakPoints.xs} {
    width: 92% !important;
  }
`;

export const GeneralModal = ({
  form,
  children,
  title,
  width,
  onFinish,
  closable,
  bodyStyle,
  onCancel,
  ...rest
}) => {
  const dispatch = useDispatch();
  const screen = useBreakpoint();

  const {isOpen} = useSelector((state) => state.modal);

  const handleCancel = () => {
    dispatch(modalActions.closeModal());
  };

  return (
    <Modal
      centered={screen.xs}
      title={title}
      open={isOpen}
      footer={null}
      width={width}
      closable={closable}
      bodyStyle={bodyStyle}
      onCancel={onCancel ? onCancel : handleCancel}
      {...rest}>
      <Form form={form} layout='vertical' onFinish={onFinish}>
        {children}
      </Form>
    </Modal>
  );
};

GeneralModal.propTypes = {
  form: PropTypes.object,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  children: PropTypes.element,
  onFinish: PropTypes.func,
  closable: PropTypes.bool,
  bodyStyle: PropTypes.object,
};
