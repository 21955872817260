import ImgCrop from 'antd-img-crop';
import {AppAvatar} from 'components/tiny';
import {ImageFormGeneral} from './ImageFormGeneral';
import {ImageUpload} from './ImageUpload';
import styled from 'styled-components';
import {UploadOutlined} from '@ant-design/icons';

const SquareDefault = styled.div`
  background: #dedfe1;
  display: inline-block;
  width: ${(props) => (props.size ? `${props.size}px` : '100%')};
  border: 1px dashed #939393;
  aspect-ratio: 1;
  object-fit: cover;
  border-radius: 10%;
`;

const CenterUploadBtn = styled.div`
  && {
    border-radius: 3px;
    min-width: max-content;
    background: transparent;
  }
`;

export const FormSquareImage = ({onChange, value, size, ...rest}) => {
  const src = value?.src || value;
  return (
    <ImageFormGeneral
      onChange={onChange}
      value={value}
      CenteredBtn={
        !src
          ? ({...rest}) => (
              <CenterUploadBtn {...rest}>
                <UploadOutlined style={{marginRight: 2}} /> Tải ảnh lên
              </CenterUploadBtn>
            )
          : undefined
      }
      {...rest}>
      <FormSquareImage_ />
    </ImageFormGeneral>
  );
};

const FormSquareImage_ = ({handleFileChange, size, value, editIcon}) => {
  const uploadProps = {
    name: 'avatar',
    showUploadList: false,
    beforeUpload: (file) => {
      handleFileChange(file);
      return false;
    },
  };

  const src = value?.src || value;

  return (
    <>
      {src ? (
        <AppAvatar type='square' src={src} size={size} />
      ) : (
        <SquareDefault size={size} />
      )}
      <ImgCrop rotate>
        <ImageUpload {...uploadProps}>{editIcon}</ImageUpload>
      </ImgCrop>
    </>
  );
};
