import {createSlice} from '@reduxjs/toolkit';

const INIT_STATE = {
  error: '',
  loading: false,
  visible: false,
  updatingContent: false,
  displayMessage: '',
  userConfig: undefined,
  listKey: undefined,
  temp: undefined,
  isCollapsed: false,
};

const commonSlice = createSlice({
  name: 'common',
  initialState: INIT_STATE,
  reducers: {
    fetchStart(state) {
      state.error = '';
      state.displayMessage = '';
      state.loading = true;
    },
    updatingContent(state) {
      state.error = '';
      state.displayMessage = '';
      state.updatingContent = true;
    },
    fetchSuccess(state) {
      state.error = '';
      state.displayMessage = '';
      state.loading = false;
      state.updatingContent = false;
    },
    showMessage(state, action) {
      state.error = '';
      state.displayMessage = action.payload;
      state.loading = false;
      state.updatingContent = false;
    },
    fetchError(state, action) {
      state.error = action.payload;
      state.displayMessage = '';
      state.loading = false;
      state.updatingContent = false;
    },
    hideMessage(state) {
      state.error = '';
      state.displayMessage = '';
      state.loading = false;
      state.updatingContent = false;
    },
    setVisible(state) {
      state.visible = !state.visible;
    },
    setListKey(state, action) {
      state.listKey = action.payload;
    },
    setTemp(state, action) {
      state.temp = action.payload;
    },
    setUserConfig(state, action) {
      state.userConfig = action.payload;
    },
    setIsCollapsed(state, action) {
      state.isCollapsed = action.payload;
    },
  },
});

export const commonActions = commonSlice.actions;

export default commonSlice.reducer;
