import React from 'react';
import {useLocation} from 'react-router-dom';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';

const SITE_URL =
  window.__RUNTIME_CONFIG__.NODE_ENV === 'development'
    ? 'http://localhost:3000'
    : 'https://revica.ossigroup.net';

const FACEBOOK_APP_ID = 'XXXXXXXXX';

const defaultTitle = 'Revica - Hướng nghiệp khoa học';
const defaultDescription = 'Revica - Hướng nghiệp khoa học';
const defaultImage =
  'https://itviec.com/rails/active_storage/representations/proxy/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBM2txRUE9PSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--00a0d28cd392379a1dc9d74a361b8c29fd227da4/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCem9MWm05eWJXRjBPZ2wzWldKd09oSnlaWE5wZW1WZmRHOWZabWwwV3dkcEFhb3ciLCJleHAiOm51bGwsInB1ciI6InZhcmlhdGlvbiJ9fQ==--bb0ebae071595ab1791dc0ad640ef70a76504047/hivetech-logo.png';
const defaultTwitter = '@hivetech';
const defaultSep = ' | ';

const AppPageMetadata = ({children, ...rest}) => {
  const {pathname} = useLocation();
  const getMetaTags = (
    {
      title,
      description,
      image,
      contentType,
      twitter,
      noCrawl,
      published = '01-05-2021',
      updated,
      category = 'Revica',
      tags = 'Revica, Hướng nghiệp, du học',
    },
    pathname,
  ) => {
    const theTitle = title
      ? (title + defaultSep + defaultTitle).substring(0, 60)
      : defaultTitle;
    const theDescription = description
      ? description.substring(0, 155)
      : defaultDescription;
    const theImage = image ? `${SITE_URL}${image}` : defaultImage;

    const metaTags = [
      {itemprop: 'name', content: theTitle},
      {itemprop: 'description', content: theDescription},
      {itemprop: 'image', content: theImage},
      {name: 'description', content: theDescription},
      {name: 'twitter:card', content: 'summary_large_image'},
      {name: 'twitter:site', content: defaultTwitter},
      {name: 'twitter:title', content: theTitle},
      {name: 'twitter:description', content: theDescription},
      {name: 'twitter:creator', content: twitter || defaultTwitter},
      {name: 'twitter:image:src', content: theImage},
      {property: 'og:title', content: theTitle},
      {property: 'og:type', content: contentType || 'website'},
      {property: 'og:url', content: SITE_URL + pathname},
      {property: 'og:description', content: theDescription},
      {property: 'og:site_name', content: defaultTitle},
      {property: 'fb:app_id', content: FACEBOOK_APP_ID},
    ];

    if (noCrawl) {
      metaTags.push({name: 'robots', content: 'noindex, nofollow'});
    }

    if (published) {
      metaTags.push({name: 'article:published_time', content: published});
    }
    if (updated) {
      metaTags.push({name: 'article:modified_time', content: updated});
    }
    if (category) {
      metaTags.push({name: 'article:section', content: category});
    }
    if (tags) {
      metaTags.push({name: 'article:tag', content: tags});
    }

    return metaTags;
  };

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: 'en',
          itemscope: undefined,
          itemtype: `http://revica.org/${
            rest.schema || 'Revica - Hướng nghiệp khoa học'
          }`,
        }}
        title={rest.title ? rest.title : defaultTitle}
        link={[
          {
            rel: 'canonical',
            href: SITE_URL + pathname,
          },
        ]}
        meta={getMetaTags(rest, pathname)}
      />
      {children}
    </>
  );
};

export default AppPageMetadata;

AppPageMetadata.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
};
