import {SearchOutlined} from '@ant-design/icons';
import {Input} from 'components/tiny';
import React from 'react';
import useFilter from '../hooks/useFilter';
import {useState} from 'react';
import debounce from 'lodash/debounce';
import {useRef} from 'react';
import {useCallback} from 'react';
import {memo} from 'react';
import {useEffect} from 'react';

export const FilterInput = memo(({name = 'keyword', ...rest}) => {
  console.log('name :', name);
  const [filter, setFilter] = useFilter();
  const [value, setValue] = useState('');
  const filterRef = useRef(filter);

  const debounceSearch = useCallback(
    debounce((value) => {
      console.log('value :', value);
      setFilter({...filterRef.current, [name]: value});
    }, 500),
    [],
  );

  useEffect(() => {
    filterRef.current = filter;
  }, [filter]);

  const handleOnChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue);
    debounceSearch(newValue);
  };

  useEffect(() => {
    const filterValue = filter?.[name];
    if (filterValue && !value) {
      setValue(filterValue);
    }
  }, [filter?.[name]]);

  return (
    <Input
      prefix={<SearchOutlined />}
      value={value}
      onChange={handleOnChange}
      allowClear
      {...rest}
    />
  );
});
