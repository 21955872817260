import {AppAvatar, EllipsisText} from 'components/tiny';
import styled from 'styled-components';

export const descriptionColumn = ({...rest} = {}) => {
  return {
    title: 'Mô tả',
    dataIndex: 'description',
    width: '550px',
    render: (value) => {
      return (
        <EllipsisText style={{maxWidth: '40vw'}} tooltip>
          {value}
        </EllipsisText>
      );
    },
    ...rest,
  };
};

const AvatarAndName = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const avatarAndNameRender = () => {
  return {
    render(value, record) {
      return (
        <AvatarAndName>
          <AppAvatar
            src={record?.imageUrl}
            size={35}
            style={{marginRight: 10}}
          />
          <span>{value}</span>
        </AvatarAndName>
      );
    },
  };
};
