import {message} from 'antd';
import authApi from 'api/auth';
import axiosClient from 'api/axiosClient';
import {CHECK_TOKEN_URL, URL_GET_TOKEN_LOGIN} from 'constants/api';
import {cookieKey} from 'constants/auth';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import {setUser} from 'pages/profile/UserProfile/slice/infoUser';
import PropTypes from 'prop-types';
import {createContext, useContext, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {commonActions} from 'redux/slice/common';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
} from '../../../../shared/constants/ActionTypes';
import {
  auth,
  facebookAuthProvider,
  githubAuthProvider,
  googleAuthProvider,
  twitterAuthProvider,
} from './firebase';

const FirebaseContext = createContext();
const FirebaseActionsContext = createContext();

export const useFirebase = () => useContext(FirebaseContext);

export const useFirebaseActions = () => useContext(FirebaseActionsContext);

// Handle api when start app
const FirebaseAuthProvider = ({children}) => {
  const [firebaseData, setFirebaseData] = useState({
    user: null,
    isAuthenticated: false,
    isLoading: true,
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleWithRememberLink = () => {
    const triedLink = localStorage.getItem('triedLink');
    if (triedLink) {
      navigate(triedLink);
      localStorage.removeItem('triedLink');
    }
  };

  useEffect(() => {
    const getAuthUser = auth.onAuthStateChanged(
      async (user) => {
        if (!user) {
          return;
        }
      },
      () => {
        setFirebaseData({
          user: null,
          isLoading: false,
          isAuthenticated: false,
        });
      },
      (completed) => {
        setFirebaseData({
          user: null,
          isLoading: false,
          isAuthenticated: completed,
        });
      },
    );

    return () => {
      getAuthUser();
    };
  }, []);

  useEffect(() => {
    const token = Cookies.get(cookieKey.token);
    const userInfo = Cookies.get(cookieKey.userInfo);
    if (token && userInfo) {
      setFirebaseData({
        user: JSON.parse(userInfo),
        isLoading: false,
        isAuthenticated: Boolean(userInfo),
      });
    } else {
      setFirebaseData({
        user: null,
        isLoading: false,
        isAuthenticated: false,
      });
    }
  }, []);

  const getProvider = (providerName) => {
    switch (providerName) {
      case 'google': {
        return googleAuthProvider;
      }
      case 'facebook': {
        return facebookAuthProvider;
      }
      case 'twitter': {
        return twitterAuthProvider;
      }
      case 'github': {
        return githubAuthProvider;
      }
      default:
        return googleAuthProvider;
    }
  };

  const signInWithPopup = async (providerName) => {
    dispatch(commonActions.fetchStart());

    try {
      const {user} = await auth.signInWithPopup(getProvider(providerName));
      const token = await user?.getIdToken();
      const {data: firebase_login} = await axiosClient.post(
        URL_GET_TOKEN_LOGIN,
        undefined,
        {
          headers: {Authorization: `Bearer ${token}`},
        },
      );
      const checkInfo = await getMoreUserInformation(firebase_login?.token);
      dispatch(commonActions.fetchSuccess());
      saveDataLoginToLocal({...checkInfo, ...firebase_login});
      handleWithRememberLink();
    } catch (error) {
      message.error('Lỗi đăng nhập!');
      setFirebaseData({
        ...firebaseData,
        isAuthenticated: false,
        isLoading: false,
      });

      dispatch({type: FETCH_ERROR, payload: error.message});
    }
  };

  const saveDataLoginToLocal = (data) => {
    if (
      data.phoneNumber === null ||
      data.dateOfBirth === null ||
      data.customerType === null
    ) {
      navigate('/signup', {state: data});
    } else {
      const user = {
        ...data,
        userName: data.username || data.email,
        fullName: data.fullName || data.name,
        displayName: data.displayName || data.name,
        imageUrl: data.imageUrl ? data.imageUrl : data.photoURL,
        phoneNumber: data.phoneNumber,
        dateOfBirth: data.dateOfBirth,
        customerType: data.customerType,
        email: data.email,
        providerId: data.providerId,
      };
      Cookies.set(cookieKey.userInfo, JSON.stringify(user));
      setFirebaseData({user: user, isAuthenticated: true, isLoading: false});
      console.groupEnd();
    }
    Cookies.set(cookieKey.token, data?.token);
    Cookies.set(cookieKey.refreshToken, data?.refreshToken);
  };

  const getMoreUserInformation = async (token) => {
    Cookies.set(cookieKey.token, token);
    const {data: checkInfo} = await axiosClient.get(CHECK_TOKEN_URL);
    Cookies.remove(cookieKey.token);
    return checkInfo;
  };

  const signInWithEmailAndPassword = async ({email, password}) => {
    dispatch({type: FETCH_START});
    try {
      const {data: dataLogin} = await authApi.signIn({
        userName: email,
        password,
      });
      const checkInfo = await getMoreUserInformation(dataLogin?.token);
      if (checkInfo?.emailVerified) {
        saveDataLoginToLocal({...checkInfo, ...dataLogin});
      } else {
        navigate('/verifyEmail', {state: checkInfo});
      }
      handleWithRememberLink();
      dispatch({type: FETCH_SUCCESS});
    } catch (error) {
      const msg = error?.response?.data?.exception;
      if (msg) {
        message.error(msg);
      }
      setFirebaseData({
        ...firebaseData,
        isAuthenticated: false,
        isLoading: false,
      });
      dispatch({type: FETCH_ERROR, payload: error.message});
    }
  };
  const createUserWithEmailAndPassword = async ({name, email, password}) => {
    dispatch({type: FETCH_START});
    try {
      const {user} = await auth.createUserWithEmailAndPassword(email, password);
      await auth.currentUser.sendEmailVerification({
        url: window.location.href,
        handleCodeInApp: true,
      });
      await auth.currentUser.updateProfile({
        displayName: name,
      });
      setFirebaseData({
        user: {...user, displayName: name},
        isAuthenticated: true,
        isLoading: false,
      });

      dispatch({type: FETCH_SUCCESS});
    } catch (error) {
      setFirebaseData({
        ...firebaseData,
        isAuthenticated: false,
        isLoading: false,
      });
      dispatch({type: FETCH_ERROR, payload: error.message});
    }
  };

  const logout = async (logoutToWp) => {
    const wp =
      Cookies.get(cookieKey.tokenWP) &&
      jwtDecode(JSON.stringify(Cookies.get(cookieKey.tokenWP)));

    await auth.signOut();
    Cookies.remove(cookieKey.token);
    Cookies.remove(cookieKey.refreshToken);
    Cookies.remove(cookieKey.userInfo);
    setFirebaseData({
      user: null,
      isLoading: false,
      isAuthenticated: false,
    });
    dispatch(setUser());
    if (logoutToWp) {
      location.href = `https://www.revica.org/wp-login.php?action=logout&_wpnonce=${wp?._wpnonce}`;
    } else {
      location.href = `https://www.revica.org/dang-nhap`;
    }
  };

  return (
    <FirebaseContext.Provider
      value={{
        ...firebaseData,
      }}>
      <FirebaseActionsContext.Provider
        value={{
          signInWithEmailAndPassword,
          createUserWithEmailAndPassword,
          signInWithPopup,
          logout,
          saveDataLoginToLocal,
        }}>
        {children}
      </FirebaseActionsContext.Provider>
    </FirebaseContext.Provider>
  );
};
export default FirebaseAuthProvider;

FirebaseAuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
