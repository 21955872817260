import {profileApi} from 'api/profile';
import {selectsApi} from 'api/selects';
import {useFetchSelects} from '../hooks';
import quizApi from 'api/quiz';
import {serviceApi} from 'api/service';

export const useFetchProfileRelation = (rest) =>
  useFetchSelects({
    api: selectsApi.profileRelation,
    key: 'ProfileRelation',
    ...rest,
  });

export const useFetchProfile = (rest) =>
  useFetchSelects({
    api: profileApi.get,
    key: 'Profile',
    moreParams: {
      page: 1,
      size: 9999,
    },
    ...rest,
  });

export const useFetchQuizzes = (rest) =>
  useFetchSelects({
    api: quizApi.getAllQuizList,
    key: 'allQuizzes',
    moreParams: {
      page: 1,
      size: 9999,
    },
    ...rest,
  });

export const useFetchQuizzesForAssignmentFilter = (rest) =>
  useFetchSelects({
    api: quizApi.getAllQuizListForAssignmentFilter,
    key: 'allQuizzesForAssignmentFilter',
    ...rest,
  });

export const useFetchQuizzesForReportFilter = (rest) =>
  useFetchSelects({
    api: quizApi.getAllQuizListForReportFilter,
    key: 'allQuizzesForReportFilter',
    ...rest,
  });
export const useListCategoriesService = (rest) =>
  useFetchSelects({
    api: serviceApi.getList,
    key: 'categoriesService',
    ...rest,
  });
