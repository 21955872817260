import {EditOutlined, ShoppingCartOutlined} from '@ant-design/icons';
import {Space} from 'antd';
import {HomeApi} from 'api/home';
import ButtonGradient from 'components/button';
import {FilterInput, FilterRangePicker} from 'components/table';
import {DATE_FORMAT} from 'constants/questions';
import moment from 'moment';

export function objectToQueryString(obj) {
  const queryParams = [];
  for (let key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];
      if (value !== null && value !== undefined) {
        queryParams.push(
          encodeURIComponent(key) + '=' + encodeURIComponent(value),
        );
      }
    }
  }

  return queryParams.join('&');
}

export const columnsBought = ({handleOpenModalWithQuiz}) => {
  return [
    {
      title: 'Dịch vụ đã mua',
      dataIndex: 'name',
      width: '50%',
      align: 'left',
      renderFilter: (
        <td colSpan={1}>
          <FilterInput placeholder='Lọc theo Dịch vụ đã mua' />
        </td>
      ),
    },
    {
      title: 'Ngày mua',
      dataIndex: 'date',
      width: '16%',
      align: 'center',
      render: (record) =>
        moment(moment(record).format('YYYY-MM-DD')).format(DATE_FORMAT),
      renderFilter: (
        <td>
          <FilterRangePicker style={{width: '100%'}} />
        </td>
      ),
    },
    {
      title: 'Số lượng còn lại',
      dataIndex: 'remainQuantity',
      align: 'center',
      width: '13%',
      sorter: true,
    },

    {
      title: 'Số lượng đã mua',
      dataIndex: 'totalQuantity',
      align: 'center',
      width: '13%',
      sorter: true,
    },
    {
      title: 'Thao tác',
      align: 'center',
      width: '12%',
      render: (_, record) => {
        return (
          <Space>
            {record?.remainQuantity !== 0 ? (
              <ButtonGradient
                onClick={async () => {
                  const {data} = await HomeApi.getquizid(record.codeProduct);
                  data ? handleOpenModalWithQuiz(data) : null;
                }}>
                <EditOutlined /> Làm ngay
              </ButtonGradient>
            ) : (
              <ButtonGradient
                onClick={() =>
                  (window.location.href = `https://www.revica.org/test/product/${record.codeProduct}`)
                }>
                <ShoppingCartOutlined /> Mua thêm
              </ButtonGradient>
            )}
          </Space>
        );
      },
    },
  ];
};
