const {createAsyncThunk, createSlice} = require('@reduxjs/toolkit');
const {HomeApi} = require('api/home');

const initialState = {
  listJobs: undefined,
  status: 'idle',
};
export const fetchListJobs = createAsyncThunk(
  'list_jobs/fetchListJobs',

  async () => {
    try {
      const {data} = await HomeApi.listJobs({page: 1, size: 9});
      return data.resource;
    } catch (err) {
      console.log('err :', err);
    }
  },
);
const list_jobs = createSlice({
  name: 'list_jobs',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchListJobs.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchListJobs.fulfilled, (state, action) => {
        state.listJobs = action.payload;
        state.status = 'idle';
      });
  },
});

export default list_jobs.reducer;
