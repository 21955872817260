/* eslint-disable react/prop-types */
import {PlusCircleOutlined, SendOutlined} from '@ant-design/icons';
import AppLogo from '@crema/core/AppLayout/components/AppLogo';
import {Button, Col, Form, Row, Space} from 'antd';
import ButtonGradient from 'components/button';
import {breakPoints} from 'global/responsive';
import {useFetchProfile} from 'hooks';
import {ModalFormProfile} from 'pages/profileManage/components/ModalForm';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import {modalActions} from '..';
import {GeneralModal} from '../GeneralModal/GeneralModal';
import ProfileListSelect from './components/ProfileList';
import {useNavigate} from 'react-router-dom';

const Title = styled.div`
	.app-logo {
		margin-bottom: 16px;
		padding: 0;
	}

	.title {
		font-weight: bold;
		font-size: 24px;
	}
`;

const WrapperModal = styled(GeneralModal)`
	.ant-modal-content {
		padding: 24px;
		@media ${breakPoints.xs} {
			.ant-modal-content {
				height: 80vh;

				.ant-form-item-control-input-content {
					height: 60vh;
					overflow: auto;
				}
			}
		}
	}

	.ant-modal-header {
		padding-top: 0;
	}

	.ant-modal-close-x {
		margin-top: 12px;
		margin-right: 12px;
	}

	.ant-modal-header,
	.ant-modal-footer {
		border: none;
		padding: 0;
	}
`;

const Footer = styled(Row)`
	.ant-btn {
		width: 100%;
		padding: inherit;

		.anticon + span,
		.ant-btn > span + .anticon {
			margin-left: 4px;
		}
	}

	.ant-col {
		margin-bottom: 0 !important;
	}
`;

function ChooseProfileModal({onStart, handleCancel, title, footer, content}) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {isOpen, record} = useSelector((state) => state.modal);
  const [whichModal, setWhichModal] = useState();

  const defaultModal = 'chooseProfile';

  const {data} = useFetchProfile();

  const clickToSubmit = () => {
    form.submit();
  };

  const handleFinish = async (values) => {
    const result = await onStart(record, values.profileId, dispatch, navigate);
    if (result !== 'failed') {
      dispatch(modalActions.closeModal());
    }
  };

  useEffect(() => {
    form.resetFields();
    // Select default to chooseProfile modal
    if (!isOpen) {
      setWhichModal(defaultModal);
    }
  }, [isOpen]);

  const handleOpenAddProfileModal = () => {
    setWhichModal('addProfile');
  };

  const afterCreateSuccessProfile = () => {
    setWhichModal('chooseProfile');
  };

  const handleReturnBack = () => {
    setWhichModal('chooseProfile');
  };

  return (
    <>
      {whichModal === 'chooseProfile' && (
        <WrapperModal
          form={form}
          onCancel={handleCancel}
          onFinish={handleFinish}
          bodyStyle={{padding: '16px 10px'}}
          title={
            title ? (
              title
            ) : (
              <Title>
                <AppLogo />
                <h1 style={{fontWeight: 700}}>Lựa chọn hồ sơ</h1>
                <span>
                  {`Chọn "Thêm hồ sơ" nếu bạn muốn làm bài test cho người khác`}
                </span>
              </Title>
            )
          }
          footer={
            footer ? (
              footer()
            ) : (
              <Footer gutter={[8, 16]}>
                <Col span={12}>
                  <Button
                    type='ghost'
                    icon={<PlusCircleOutlined />}
                    onClick={handleOpenAddProfileModal}>
                    Thêm hồ sơ
                  </Button>
                </Col>
                <Col span={12}>
                  <ButtonGradient
                    icon={<SendOutlined />}
                    onClick={clickToSubmit}>
                    Bắt đầu làm test
                  </ButtonGradient>
                </Col>
              </Footer>
            )
          }>
          {content ? (
            content()
          ) : (
            <ProfileListSelect data={data?.data?.resource} />
          )}
        </WrapperModal>
      )}

      {whichModal === 'addProfile' && (
        <ModalFormProfile
          component='chooseProfile'
          onAddSuccess={afterCreateSuccessProfile}
          footerBtn={
            <Space>
              <Button type='ghost' onClick={handleReturnBack}>
                Quay lại
              </Button>
              <Button type='primary' htmlType='submit'>
                Lưu
              </Button>
            </Space>
          }
        />
      )}
    </>
  );
}

export {ChooseProfileModal};
