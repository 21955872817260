import {ExclamationCircleTwoTone} from '@ant-design/icons';
import {Button, Modal} from 'antd';
import styled from 'styled-components';

const StyModal = styled(Modal)`
  .ant-modal-footer {
    text-align: center;
    padding: 16px;
  }
`;

const Text = styled.div`
  padding: 16px;
  font-weight: 500;
  margin: 0;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const IsAccount = () => {
  const signIn = () => {
    return (location.href = 'https://www.revica.org/dang-nhap/');
  };
  const signUp = () => {
    return (location.href = 'https://www.revica.org/dang-ky/');
  };
  return (
    <StyModal
      centered
      open={true}
      closable={false}
      footer={[
        <Button
          key='signUp'
          onClick={signUp}
          type='default'
          style={{marginRight: 16}}>
          Đăng ký
        </Button>,
        <Button type='primary' key='signIn' onClick={signIn}>
          Đăng Nhập
        </Button>,
      ]}>
      <Text>
        <ExclamationCircleTwoTone
          twoToneColor='#e7be66'
          style={{fontSize: 20, marginRight: 8}}
        />
        Bạn cần đăng nhập để thực hiện thao tác này!
      </Text>
    </StyModal>
  );
};

export default IsAccount;
