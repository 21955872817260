import {userConfigApi} from 'api/config';
import {useFetchSimple} from 'hooks';
import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {commonActions} from 'redux/slice/common';

export const GlobalHook = ({children}) => {
  const dispatch = useDispatch();
  // Get user config and send to redux
  const {data} = useFetchSimple({
    api: userConfigApi.get,
    key: 'userConfig',
  });

  useEffect(() => {
    if (data) {
      dispatch(commonActions.setUserConfig(data?.data));
    }
  }, [data]);

  return <>{children}</>;
};
