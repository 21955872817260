import React from 'react';
import {Layout} from 'antd';
import './index.style.less';
import AppLogo from '../components/AppLogo';
import {ExploreButton, InvisibleHeaderBlock} from '../components/Common';

const AppHeader = () => {
  const {Header} = Layout;

  return (
    <Header className='app-header-mini'>
      <AppLogo />
      <ExploreButton />
      <InvisibleHeaderBlock />
    </Header>
  );
};

export default AppHeader;

AppHeader.propTypes = {};
