import {whichPage} from 'global/whichPage';
import {HeaderDoQuiz} from './HeaderDoQuiz';

export const whichComponent = (type) => {
  switch (type) {
    case whichPage.headerDoQuiz:
      return HeaderDoQuiz;
    default:
      return () => <span>Không tìm thấy header của trang này</span>;
  }
};
