export const cookieKey = {
  token: 'token',
  refreshToken: 'refreshToken',
  userInfo: 'userInfo',
  newToken: 'newToken',
  information: 'information',
  code: 'code',
  tokenWP: 'tokenWP',
  oldService: 'oldService',
};

export const permissions = {
  dashboard: 'DASHBOARD',
  profiles: 'PROFILES',
  users: 'USERS',
  roles: 'ROLES',
  allQuiz: 'ALL_QUIZ',
  myQuiz: 'MY_QUIZ',
  services: 'SERVICES',
  quizForm: 'NEW_QUIZ',
  historyAdmin: 'HISTORY_ADMIN',
  historyUser: 'HISTORY_USER',
};
