import React from 'react';
import './index.style.less';
import styled from 'styled-components';
import {themeSelector} from 'global/themeUtils';

const CircleGreen = styled.div`
  && {
    background-color: ${themeSelector.green};
  }
`;

const CirclePurple = styled.div`
  && {
    background-color: ${themeSelector.charcoalGreen};
  }
`;

const CircleYellow = styled.div`
  && {
    background-color: ${themeSelector.yellow};
  }
`;

const Description = styled.div`
  font-weight: 600;
  font-size: 28px;
  color: ${themeSelector.green};
`;

const CircleContainer = styled.div`
  display: flex;
`;

const AppLoader = ({description}) => {
  return (
    <div className='app-loader'>
      <div className='overflow white' id='preload'>
        <div className='circle-line'>
          {description && <Description>{description}</Description>}
          <CircleContainer>
            <div className='circle-red'>&nbsp;</div>
            <CirclePurple className='circle-blue'>&nbsp;</CirclePurple>
            <CircleGreen className='circle-green'>&nbsp;</CircleGreen>
            <CircleYellow className='circle-yellow'>&nbsp;</CircleYellow>
          </CircleContainer>
        </div>
      </div>
    </div>
  );
};

export default AppLoader;
