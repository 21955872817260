export const EQuestionComplexType = {
  TRADITIONAL_DISC: 'TRADITIONAL_DISC',
  PDHT: 'PDHT',
  WRITTING: 'WRITTING',
  CAL_SCORE: 'CAL_SCORE',
  HOLLAND: 'HOLLAND',
  EQ: 'EQ',
};
export const EQuestionType = {
  SINGLE_DISC: 'SINGLE_DISC',
  LEVEL: 'LEVEL',
  NUMBER: 'NUMBER',
  DATE: 'DATE',
  HEAD_CIRCUMFERENCE: 'HEAD_CIRCUMFERENCE',
  CHEEK: 'CHEEK',
  SINGLE_NORMAL: 'SINGLE_NORMAL',
  MULTI_DISC: 'MULTI_DISC',
  UPLOADABLE: 'UPLOADABLE',
  MULTI_CHOICE: 'MULTI_CHOICE',
  SINGLE_SCORE: 'SINGLE_SCORE',
};
export const EQuestionSimpleType = {
  TRADITIONAL_DISC: 'TRADITIONAL_DISC',
  CAL_SCORE: 'CAL_SCORE',
  ANALYSIS_WRITTING: 'ANALYSIS_WRITTING',
  HOLLAND: 'HOLLAND',
  WRITTING: 'WRITTING',
  EQ: 'EQ',
};
export const EQuestionStatus = {
  DRAFT: 'DRAFT',
  PUBLISHED: 'PUBLISHED',
  CLOSE: 'CLOSE',
};

export const EHeightFace = {
  HIGH: 'HIGH',
  MEDIUM: 'MEDIUM',
  LOW: 'LOW',
};

export const ECircleFace = {
  ROUND: 'ROUND',
  MEDIUM: 'MEDIUM',
  FLAT: 'FLAT',
};

export const historyStatus = {
  COMPLETED: 'COMPLETED',
  DRAFT: 'DRAFT',
  PENDING: 'PENDING',
  UNSATISFACTORY: 'UNSATISFACTORY',
  WAIT_REVIEW: 'WAIT_REVIEW',
};
