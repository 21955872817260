import axios from 'axios';
import {URL_GET_TOKEN_LOGIN, URL_REFRESH_TOKEN} from 'constants/api';
import {cookieKey} from 'constants/auth';
import Cookies from 'js-cookie';
import queryString from 'query-string';
import authApi from './auth';
import firebase from 'firebase/app';
import jwtDecode from 'jwt-decode';

// Create axios
const axiosClient = axios.create({
  baseURL: window.__RUNTIME_CONFIG__.REACT_APP_BASE_URL,
  headers: {
    'content-type': 'application/json',
    crossdomain: true,
  },
  paramsSerializer: (params) =>
    queryString.stringify(params, {skipNull: true, skipEmptyString: true}),
});

// Handle token
axiosClient.interceptors.request.use((config) => {
  const storeToken = Cookies.get(cookieKey.token)
    ? `Bearer ${Cookies.get(cookieKey.token)}`
    : '';

  if (storeToken) {
    if (
      config.url !== URL_GET_TOKEN_LOGIN &&
      config.url !== URL_REFRESH_TOKEN
    ) {
      config.headers = {
        ...config?.headers,
        'Content-Type': 'application/json',
        Authorization: storeToken,
      };
    }
  }
  return config;
});

// Define an array to store the failed requests
let failedRequests = [];
let isRefreshing = false;

// Handle when token or refresh token expires
axiosClient.interceptors.response.use(
  (response) => {
    if (response?.status === 200) {
      return response;
    }
    return Promise.reject(response);
  },
  async (error) => {
    const refresh_Token = Cookies.get(cookieKey.refreshToken);
    const userInfoJson = Cookies.get('userInfo');
    const userInfo = userInfoJson ? JSON.parse(userInfoJson) : null;
    const SIGN_IN_PATH = 'signin';
    const jwt_token_WP =
      Cookies.get(cookieKey.tokenWP) &&
      jwtDecode(JSON.stringify(Cookies.get(cookieKey.tokenWP)));
    const patchName = window.location.pathname;
    const auth = firebase.auth();
    let response = error.response;
    if ([401].includes(response?.status)) {
      // sign out
      if (jwt_token_WP._wpnonce) {
        window.location.href = 'https://www.revica.org/dang-nhap';
      } else if (!patchName.includes(SIGN_IN_PATH)) {
        await auth.signOut();
        Cookies.remove(cookieKey.token);
        Cookies.remove(cookieKey.refreshToken);
        Cookies.remove(cookieKey.userInfo);
        window.location.href = '/signin';
      }
    } else if (response?.status === 410) {
      // Push failed request onto the stack and refresh token
      failedRequests.push({
        method: error.config.method,
        url: error.config.url,
        data: error.config.data,
      });
      if (!isRefreshing) {
        isRefreshing = true;
        try {
          const {data} = await authApi.refresh({
            refreshToken: refresh_Token,
            userName: userInfo.userName,
          });
          Cookies.set(cookieKey.token, data?.data.token);
          // Retry failed requests
          await Promise.all(
            failedRequests.map(async (request) => {
              try {
                const response = await axiosClient.request(request);
              } catch (error) {
                console.error(error);
              }
            }),
          );
          failedRequests = [];
        } catch (error) {
          console.error(error);
        }
        isRefreshing = false;
      }
    }
    return Promise.reject(error);
  },
);

export default axiosClient;
