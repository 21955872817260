import {Tag} from 'antd';
import ETag from 'components/Tag';
import {quizStatus, role} from 'constants/common';

// Role
export const getRoleText = (apiText) => {
  if (apiText === role.admin) return 'Admin';
  return 'Người dùng';
};

export const getRoleTag = (text) => {
  text = getRoleText(text);
  if (text) {
    let color;
    text === 'Admin' ? (color = 'red') : (color = 'blue');
    return <Tag color={color}>{text}</Tag>;
  }
};

// QuizStatus
export const getQuizStatusText = (apiText) => {
  if (apiText === quizStatus.published) return 'Đã công bố';
  if (apiText === quizStatus.draft) return 'Dự thảo';
  return undefined;
};

export const getQuizStatusTag = (text) => {
  text = getQuizStatusText(text);
  if (text) {
    let color;
    if (text === 'Đã công bố') color = 'rgb(21, 128, 61)';
    if (text === 'Dự thảo') color = 'rgb(26, 85, 203)';
    if (text === 'Đóng') color = 'rgb(185, 28, 28)';
    return <ETag color={color}>{text}</ETag>;
  }
};
