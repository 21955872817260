import {
  CHANGE_PASSWORD_URL,
  CHECK_OLD_PASSWORD_URL,
  PUT_USER_PERMISSIONS_URL,
  USER_INFOR_URL,
  USER_URL,
} from '../constants/api';
import axiosClient from './axiosClient';

export const userApi = {
  getList(params) {
    return axiosClient.get(USER_URL, {params});
  },
  updateUser(id, payload) {
    return axiosClient.put(USER_URL + '/' + id, payload);
  },
  putPerson(payload) {
    return axiosClient.put(PUT_USER_PERMISSIONS_URL, payload);
  },
  getInfoUser() {
    return axiosClient.get(USER_INFOR_URL);
  },
  changePassword(payload) {
    return axiosClient.put(CHANGE_PASSWORD_URL, payload);
  },
  check_old_password(payload) {
    return axiosClient.post(CHECK_OLD_PASSWORD_URL, payload);
  },
};
