import {Grid, Layout} from 'antd';
import clsx from 'clsx';
import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {commonActions} from 'redux/slice/common';
import {FooterType} from '../../../../shared/constants/AppEnums';
import {AppContentView} from '../../../index';
import {useLayoutContext} from '../../../utility/AppContextProvider/LayoutContextProvider';
import {isEmpty} from '../../../utility/GlobalHelper';
import AppScrollbar from '../../AppScrollbar';
import AppThemeSetting from '../../AppThemeSetting';
import AppFooter from '../components/AppFooter';
import AppHeader from './AppHeader';
import AppSidebar from './AppSidebar';
import './index.style.less';
import {useLocation} from 'react-router-dom';

const {useBreakpoint} = Grid;

const Default = () => {
  const width = useBreakpoint();
  const {footer, footerType} = useLayoutContext();
  const dispatch = useDispatch();
  const onToggleSidebar = (value) => {
    dispatch(commonActions.setIsCollapsed(value));
  };

  useEffect(() => {
    if (!isEmpty(width)) {
      if (width.xl) {
        dispatch(commonActions.setIsCollapsed(false));
      } else {
        dispatch(commonActions.setIsCollapsed(true));
      }
    }
  }, [width]);

  return (
    <Layout
      className={clsx('app-layout', {
        appMainFooter: footer && footerType === FooterType.FLUID,
        appMainFixedFooter: footer && footerType === FooterType.FIXED,
      })}>
      <AppSidebar onToggleSidebar={onToggleSidebar} />
      <Layout className='app-layout-main'>
        <AppHeader onToggleSidebar={onToggleSidebar} />
        <AppScrollbar className='main-scrollbar'>
          <AppContentView />
          <AppFooter />
        </AppScrollbar>
      </Layout>
      <AppThemeSetting />
    </Layout>
  );
};

export default React.memo(Default);
