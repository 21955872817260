import React from 'react';
import styled, {keyframes} from 'styled-components';
import {DribbbleOutlined} from '@ant-design/icons';

const slide = keyframes`
  0% {
    transform: translateX(-200px) rotate(0deg);
  }
  50% {
    transform: translateX(200px) rotate(360deg);
  }
  100% {
    transform: translateX(-200px) rotate(0deg);
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const Icon = styled(DribbbleOutlined)`
  font-size: 35px;
  animation: ${slide} 4s ease-in-out infinite alternate;
`;

const Text = styled.p`
  font-size: 20px;
  margin-top: 8px;
  font-weight: bold;
  white-space: nowrap;

  &:after {
    content: '';
    animation: ellipsis 1.8s infinite;
  }

  @keyframes ellipsis {
    0% {
      content: '';
    }
    33% {
      content: '.';
    }
    66% {
      content: '..';
    }
    100% {
      content: '...';
    }
  }
`;

export const BlockLoading = () => {
  return (
    <Container>
      <Icon />
      <Text>Loading</Text>
    </Container>
  );
};
