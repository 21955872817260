import {Avatar, Dropdown, Tag} from 'antd';
import clsx from 'clsx';
import Cookies from 'js-cookie';
import {fetchUserInfo} from 'pages/profile/UserProfile/slice/infoUser';
import PropTypes from 'prop-types';
import {useEffect} from 'react';
import {FaChevronDown} from 'react-icons/fa';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {useSidebarContext} from '../../../../utility/AppContextProvider/SidebarContextProvider';
import {useThemeContext} from '../../../../utility/AppContextProvider/ThemeContextProvider';
import {useAuthMethod} from '../../../../utility/AuthHooks';
import './index.style.less';

const UserInfo = ({hasColor}) => {
  const {themeMode} = useThemeContext();
  const {logout} = useAuthMethod();
  const dispatch = useDispatch();

  const {user} = useSelector((state) => state.userInfo);
  useEffect(() => {
    dispatch(fetchUserInfo());
  }, []);

  const navigate = useNavigate();
  const {sidebarColorSet} = useSidebarContext();
  const {isSidebarBgImage} = useSidebarContext();
  const isLogoutWp = Cookies.get('tokenWP');
  const getUserAvatar = () => {
    if (user?.displayName) {
      return user?.displayName.charAt(0).toUpperCase();
    }
    if (user?.email) {
      return user?.email.charAt(0).toUpperCase();
    }
  };

  const menu = [
    // Ẩn menu trang thông tin cá nhân
    // {
    //   key: '1',
    //   label: (
    //     <div onClick={() => navigate('/my-profile')}>Thông tin của tôi</div>
    //   ),
    // },
    {
      key: '2',
      label: (
        <div onClick={() => (isLogoutWp ? logout(isLogoutWp) : logout())}>
          Đăng xuất
        </div>
      ),
    },
  ];

  return (
    <>
      {hasColor ? (
        <div
          style={{
            backgroundColor: isSidebarBgImage
              ? ''
              : sidebarColorSet.sidebarHeaderColor,
            color: sidebarColorSet.sidebarTextColor,
          }}
          className={clsx('cr-user-info cr-user-info-hasColor', {
            light: themeMode === 'light',
          })}>
          <Dropdown
            className='user-profile-dropdown'
            menu={{items: menu}}
            trigger={['click']}
            placement='bottomRight'
            overlayStyle={{
              zIndex: 1052,
              minWidth: 150,
            }}>
            <a className='cr-user-info-inner ant-dropdown-link'>
              <div className='gradient-avatar'>
                {user?.imageUrl ? (
                  <Avatar
                    className='cr-user-info-avatar'
                    src={user?.imageUrl}
                  />
                ) : (
                  <Avatar className='cr-user-info-avatar'>
                    {getUserAvatar()}
                  </Avatar>
                )}
              </div>
              <span className='cr-user-info-content'>
                <span className='cr-user-name-info'>
                  <h3
                    className={clsx('cr-user-name text-truncate', {
                      light: themeMode === 'light',
                    })}>
                    {user?.displayName ? user.displayName : 'admin user '}
                  </h3>
                  <span className='cr-user-arrow'>
                    <FaChevronDown />
                  </span>
                </span>
                <span className='cr-user-designation text-truncate'>
                  <Tag color={user?.roleName === 'Admin' ? 'red' : 'blue'}>
                    {user?.roleName}
                  </Tag>
                </span>
              </span>
            </a>
          </Dropdown>
        </div>
      ) : (
        <div
          className={clsx('cr-user-info', {
            light: themeMode === 'light',
          })}>
          <Dropdown
            className='user-profile-dropdown'
            menu={{items: menu}}
            trigger={['click']}
            placement='bottomRight'
            overlayStyle={{
              zIndex: 1052,
              minWidth: 150,
            }}>
            <a className='cr-user-info-inner ant-dropdown-link'>
              <div className='gradient-avatar'>
                {user?.photoURL ? (
                  <Avatar className='cr-user-info-avatar' src={user.photoURL} />
                ) : (
                  <Avatar className='cr-user-info-avatar'>
                    {getUserAvatar()}
                  </Avatar>
                )}
              </div>
              <span className='cr-user-info-content'>
                <span className='cr-user-name-info'>
                  <h3
                    className={clsx('cr-user-name text-truncate', {
                      light: themeMode === 'light',
                    })}>
                    {user?.displayName ? user.displayName : 'admin user '}
                  </h3>
                  <span className='cr-user-arrow'>
                    <FaChevronDown />
                  </span>
                </span>
                <span className='cr-user-designation text-truncate'>
                  Quản lý hệ thống
                </span>
              </span>
            </a>
          </Dropdown>
        </div>
      )}
    </>
  );
};

export default UserInfo;

UserInfo.propTypes = {
  hasColor: PropTypes.bool,
};
