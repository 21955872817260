import {PlusCircleOutlined} from '@ant-design/icons';
import AppTableContainer from '@crema/core/AppTableContainer';
import {Button} from 'antd';
import clsx from 'clsx';
import {SORT} from 'common/constants/home';
import {useAddModal} from 'components/modal';
import {PageCard} from 'components/tiny';
import PropTypes from 'prop-types';
import {useMemo} from 'react';
import {useLocation, useSearchParams} from 'react-router-dom';
import styled from 'styled-components';
import {Pagination, useFilter} from '..';
import useSubscribeList from '../hooks/useSubscribeList';
import './styles.less';
import {objectToQueryString} from 'pages/ListProductsUser/constant';

const StyledCard = styled(PageCard)`
  .ant-table-thead {
    .ant-table-cell {
      text-align: center;
    }
  }

  .ant-card-body {
    padding: 0 20px 16px;
  }
`;

const HeaderCount = styled.div`
  font-weight: normal;
  font-size: 13px;
  margin: 4px 0 12px 4px;
`;

export default function Table({
  className,
  title,
  extra,
  data,
  tableClassName,
  columns,
  rowKey,
  addBtn,
  ...rest
}) {
  const handleAdd = useAddModal();
  const [{page, size}] = useFilter();
  const {data: wholeData} = useSubscribeList() || {};
  const total = wholeData?.total;
  const startPage = (page - 1) * size + 1;
  const endPage = Math.min(total, page * size);

  const {search} = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const urlParseSearchParams = Object.fromEntries(urlSearchParams.entries());
  // eslint-disable-next-line
  const [_, setParams] = useSearchParams();

  const colsWithSort = useMemo(() => {
    if (columns) {
      return columns.map((col) => {
        if (col.sorter) {
          col.sortOrder =
            urlParseSearchParams.sortBy === col.dataIndex
              ? urlParseSearchParams.sortType === SORT.ASC
                ? 'ascend'
                : 'descend'
              : undefined;
        }
        return col;
      });
    }
    return columns;
  }, [columns, urlParseSearchParams.sortBy, urlParseSearchParams.sortType]);

  return (
    <StyledCard
      className={clsx('no-card-space-ltr-rtl table', className)}
      title={title}
      moreTitle={
        <>
          {addBtn && (
            <Button
              type='ghost'
              icon={<PlusCircleOutlined />}
              onClick={handleAdd}>
              {`Thêm  ${typeof addBtn === 'string' ? addBtn : ''}`}
            </Button>
          )}
        </>
      }
      extra={extra}>
      {total && size && (
        <HeaderCount>{`${startPage} - ${endPage} trong số ${total} dòng`}</HeaderCount>
      )}
      <AppTableContainer
        {...rest}
        hoverColor
        className={clsx('table-main', tableClassName)}
        data={data}
        columns={colsWithSort}
        rowKey={rowKey}
        onChange={(value, _, sorter) => {
          let newParams = {};
          if (!Array.isArray(sorter)) {
            if (sorter.field && sorter.order) {
              newParams.sortType =
                sorter.order === 'ascend' ? SORT.ASC : SORT.DESC;
              newParams.sortBy = sorter.field?.toString();
            } else {
              newParams.sortType = undefined;
              newParams.sortBy = undefined;
            }
          }
          setParams(objectToQueryString(newParams));
        }}
      />
      <Pagination />
    </StyledCard>
  );
}

Table.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  tableClassName: PropTypes.string,
  columns: PropTypes.array,
  data: PropTypes.array,
  extra: PropTypes.node,
  rowKey: PropTypes.string,
  addBtn: PropTypes.bool,
};
