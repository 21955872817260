import styled from 'styled-components';
import ButtonGradient from '../../../../components/button';
import React from 'react';
import {useIntl} from 'react-intl';

export const InvisibleHeaderBlock = styled.div`
  display: block;
  width: 40px;
  height: 40px;

  @media screen and (min-width: 768px) {
    display: none;
  }
`;

const SectionDesktop = styled.div`
  display: none;

  @media screen and (min-width: 768px) {
    display: flex;
    margin-left: auto;
    align-items: center;
  }
`;

export const ExploreButton = () => {
  const {messages} = useIntl();

  return (
    <SectionDesktop>
      <ButtonGradient
        onClick={() => window.open('https://www.revica.org/', '_blank')}>
        {messages['button.discover']}
      </ButtonGradient>
    </SectionDesktop>
  );
};