import {Input} from 'antd';
import clsx from 'clsx';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import PropTypes from 'prop-types';
import './index.style.less';
import {useFilter} from 'components/table';

const {Search} = Input;

export default function AppSearch({className, ...rest}) {
  const {messages} = useIntl();
  const [filter, setFilter] = useFilter();
  const [value, setValue] = useState('');

  const onChange = (e) => {
    setValue(e.target.value);
  };
  const onSearch = (e) => {
    setFilter({...filter, keyword: e});
  };

  useEffect(() => {
    const keyword = filter?.keyword;
    if (keyword) {
      setValue(keyword);
    }
  }, [filter?.keyword]);

  return (
    <Search
      {...rest}
      onChange={onChange}
      value={value}
      onSearch={onSearch}
      defaultValue
      className={clsx('app-search', className)}
      placeholder={messages['common.searchHere']}
    />
  );
}

AppSearch.propTypes = {
  className: PropTypes.string,
};
