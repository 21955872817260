import quizApi from 'api/quiz';
import {EQuestionComplexType} from 'shared/constants/enumsQuestion';

const {createAsyncThunk, createSlice} = require('@reduxjs/toolkit');

const initialState = {
  listQuizzes: undefined,
  status: 'idle',
  traditionalDISC: [],
  writing: [],
  score: [],
  holland: [],
};
export const fetchListQuizzes = createAsyncThunk(
  'list_quizzes/fetchListQuizs',
  async () => {
    try {
      const {data} = await quizApi.getList({page: 1, size: 10});
      return data.resource;
    } catch (err) {
      console.log('err :', err);
    }
  },
);
const list_quizzes = createSlice({
  name: 'list_quizzes',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchListQuizzes.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchListQuizzes.fulfilled, (state, action) => {
        state.listQuizzes = action.payload;
        state.status = 'idle';
        action.payload?.map((item) => {
          if (item.questionComplex === EQuestionComplexType.TRADITIONAL_DISC) {
            state.traditionalDISC = item;
          } else if (item.questionComplex === EQuestionComplexType.WRITTING) {
            state.writing = item;
          } else if (item.questionComplex === EQuestionComplexType.CAL_SCORE) {
            let scores = [];
            scores.push(item);
            state.score = scores;
          } else if (item.questionComplex === EQuestionComplexType.HOLLAND) {
            state.holland = item;
          }
        });
      });
  },
});

export default list_quizzes.reducer;
