import {Col, DatePicker, Form, Input, Row} from 'antd';
import {commonApi} from 'api/common';
import {profileApi} from 'api/profile';
import {FormAvatar, ProfileRelationSelect} from 'components/form';
import {Modal} from 'components/modal';
import {TextArea} from 'components/tiny';
import {timeFormat} from 'constants/common';
import {DATE_FORMAT} from 'constants/questions';
import {breakPoints} from 'global/responsive';
import moment from 'moment';
import {useSelector} from 'react-redux';
import styled from 'styled-components';

const StyledRow = styled(Row)`
  @media ${breakPoints.xs} {
    flex-direction: column;
  }
`;

export const ModalFormProfile = ({
  component,
  onAddSuccess,
  footerBtn,
  disabled,
}) => {
  const [form] = Form.useForm();
  const {record, type} = useSelector((state) => state.modal);

  const handleFinish = async (values) => {
    console.log('values :', values);
    const img = values.imageUrl;
    let resultImgUpload = null;
    if (img?.file) {
      const imgRes = await commonApi.upload(img.file);
      resultImgUpload = imgRes.data.urlImage;
    }
    delete values.imageUrl;
    const final = {
      ...values,
      dateOfBirth: values?.dateOfBirth
        ? values?.dateOfBirth?.format(DATE_FORMAT)
        : undefined,
    };

    if (resultImgUpload) {
      final.imageUrl = resultImgUpload;
    }
    if (type === 'add') {
      await profileApi.add(final);
      if (onAddSuccess) {
        onAddSuccess(final);
      }
    }

    if (type === 'edit') {
      await profileApi.edit(record?.profileId, final);
    }
  };

  const initialValues = (values) => {
    return {
      dateOfBirth: values?.dateOfBirth
        ? moment(values?.dateOfBirth)
        : undefined,
    };
  };

  return (
    <Modal
      form={form}
      titleTopic={'Hồ sơ'}
      width='40%'
      onFinish={handleFinish}
      footerBtn={footerBtn}
      noCloseModalWhenSuccess={component === 'chooseProfile'}
      initialValues={initialValues}
      disabled={disabled}>
      <StyledRow gutter={20}>
        <Col sm={8} xs={8}>
          <Form.Item name='imageUrl'>
            <FormAvatar disabled={disabled} />
          </Form.Item>
        </Col>
        <Col flex={1}>
          <Form.Item
            name='name'
            label={'Họ và tên'}
            required
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập họ và tên',
              },
            ]}>
            <Input placeholder='Nhập họ và tên' disabled={disabled} />
          </Form.Item>

          <Form.Item
            name='relation'
            required
            label={'Quan hệ'}
            rules={[
              {
                required: true,
                message: 'Vui lòng chọn quan hệ',
              },
            ]}>
            <ProfileRelationSelect
              placeholder={'Chọn quan hệ'}
              disabled={disabled}
            />
          </Form.Item>

          <Form.Item name='dateOfBirth' label={'Ngày sinh'}>
            <DatePicker
              style={{width: '100%'}}
              format={timeFormat.vnFullDate}
              placeholder='Chọn ngày sinh'
              defaultPickerValue={moment('02/03/1995', timeFormat.vnFullDate)}
              disabled={disabled}
            />
          </Form.Item>

          <Form.Item name='address' label={'Địa chỉ'}>
            <TextArea
              placeholder='Nhập địa chỉ'
              autoSize={{minRows: 4}}
              disabled={disabled}
            />
          </Form.Item>
        </Col>
      </StyledRow>
    </Modal>
  );
};
