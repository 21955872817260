const variables = {
  yellow: '#CE9B25',
  green: '#178D9C',
  blue: '#096dd9',
  charcoalGreen: '#112145',
};

const theme = {
  ...variables,
  primary: variables.yellow,
};

export default theme;
