import {Tooltip} from 'antd';
import styled from 'styled-components';

const StyledText = styled.p`
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  margin-bottom: 0;
`;

export const EllipsisText = ({
  tooltip = false,
  maxCharacterToolTip = 1400,
  children,
  ...rest
}) => {
  const text =
    typeof children === 'string' && children.length > maxCharacterToolTip + 3
      ? children.slice(0, maxCharacterToolTip) + '...'
      : children;

  if (!tooltip) return <StyledText {...rest}>{text}</StyledText>;

  if (tooltip)
    return (
      <Tooltip title={text}>
        <StyledText {...rest}>{text}</StyledText>
      </Tooltip>
    );
};

const PreTextStyled = styled.div`
  white-space: pre-line;
  overflow: auto;
  text-align: justify;
`;

export const PreText = ({...rest}) => {
  return <PreTextStyled {...rest} />;
};
