import {SearchOutlined} from '@ant-design/icons';
import {Select} from 'components/tiny';
import React from 'react';
import useFilter from '../hooks/useFilter';

export const FilterSelect = ({name, children, ...rest}) => {
  const [filter, setFilter] = useFilter();

  const handleOnChange = (value) => {
    if (value) {
      setFilter({
        ...filter,
        [name]: value,
      });
    } else {
      delete filter?.[name];
      setFilter({...filter});
    }
  };

  const commonProps = {
    value: filter?.[name],
    onChange: handleOnChange,
  };

  return children ? (
    React.cloneElement(children, {...commonProps})
  ) : (
    <Select {...commonProps} suffixIcon={<SearchOutlined />} {...rest} />
  );
};
