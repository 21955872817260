// For table
export const defaultParams = {
  page: 1,
  size: 10,
};

export const timeFormat = {
  vnDate: 'DD/MM',
  vnFullDate: 'DD/MM/YYYY',
  time: 'HH:mm',
  fullDateTime: 'HH:mm - DD/MM/YYYY',
};

export const role = {
  admin: 'ADMINISTRATOR',
  user: 'USER',
};

export const quizStatus = {
  published: 'PUBLISHED',
  draft: 'DRAFT',
};
