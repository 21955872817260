import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {commonActions} from 'redux/slice/common';
import {useFetch} from 'hooks';

export default function useList({key, api}) {
  const dispatch = useDispatch();
  const query = useFetch({api, queryKeyArrWithFilter: [key], withFilter: true});

  useEffect(() => {
    dispatch(commonActions.setListKey(undefined));
  }, []);

  useEffect(() => {
    if (key) {
      dispatch(commonActions.setListKey(key));
    }
  }, [key]);

  return query;
}
