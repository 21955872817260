import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  profileId: '',
  quizStart: undefined,
};
const homeQuizSlice = createSlice({
  name: 'homeQuiz',
  initialState: initialState,
  reducers: {
    setProfileId(state, action) {
      state.profileId = action.payload;
    },
    setQuizStart(state, action) {
      state.quizStart = action.payload;
    },
  },
});

export const {setProfileId, setQuizStart} = homeQuizSlice.actions;

export default homeQuizSlice.reducer;
