import React from 'react';
import {Table} from 'antd';
import './index.style.less';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import QueueAnim from 'rc-queue-anim';
import {useMemo} from 'react';
import styled from 'styled-components';

const TableRowFilter = styled.tr`
  td {
    padding: 16px 4px;
  }
`;

const AppTableContainer = (props) => {
  const {columns, data, pagination, hoverColor, className, rowKey, ...rest} =
    props;

  const customHeaderTable = useMemo(() => {
    return {
      header: {
        row: (e) => {
          return (
            <>
              <tr>{e.children}</tr>
              <TableRowFilter>
                {columns.map((col) => (
                  <React.Fragment key={col.title}>
                    {col.renderFilter}
                  </React.Fragment>
                ))}
              </TableRowFilter>
            </>
          );
        },
      },
    };
  }, []);

  return (
    <QueueAnim
      component={Table}
      type='left'
      className={clsx('table-responsive', {hoverColor: hoverColor}, className)}
      components={customHeaderTable}
      columns={columns}
      dataSource={data}
      scroll={{x: 'max-content'}}
      rowKey={rowKey}
      pagination={pagination}
      {...rest}
    />
  );
};

export default AppTableContainer;

AppTableContainer.propTypes = {
  columns: PropTypes.any,
  data: PropTypes.array,
  rowKey: PropTypes.string,
  className: PropTypes.string,
  pagination: PropTypes.bool,
  hoverColor: PropTypes.bool,
};

AppTableContainer.defaultProps = {
  pagination: false,
  rowKey: 'id',
};
