import React from 'react';
import {Layout} from 'antd';
import './index.style.less';
import AppLogo from '../components/AppLogo';
import PropTypes from 'prop-types';
import {AiOutlineMenu} from 'react-icons/ai';
import styled from 'styled-components';
import {useSelector} from 'react-redux';
import {whichComponent} from './PageHeader/whichComponent';
import {breakPoints} from 'global/responsive';
import {ExploreButton, InvisibleHeaderBlock} from '../components/Common';

const HeaderLeft = styled.div`
  display: flex;
  align-items: stretch;
  gap: 20px;
  height: 100%;
`;

const ResponsiveComponent = styled.div`
  display: flex;
  @media ${breakPoints.xs} {
    display: none;
  }
`;

const AppHeader = ({onToggleSidebar}) => {
  const {Header} = Layout;
  const {temp, isCollapsed} = useSelector((state) => state.common);

  const PageComponent = whichComponent(temp?.type);

  return (
    <Header className='app-header'>
      <a className='trigger' onClick={() => onToggleSidebar(!isCollapsed)}>
        <AiOutlineMenu />
      </a>
      <HeaderLeft>
        <AppLogo />
        {temp && (
          <ResponsiveComponent>
            <PageComponent data={temp} />
          </ResponsiveComponent>
        )}
      </HeaderLeft>
      <ExploreButton />
      <InvisibleHeaderBlock />
    </Header>
  );
};

export default AppHeader;

AppHeader.propTypes = {
  onToggleSidebar: PropTypes.func,
  isCollapsed: PropTypes.bool,
};
