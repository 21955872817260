import {useSubscribeQuery} from 'hooks';
import useFilter from './useFilter';
import {useSelector} from 'react-redux';

export default function useSubscribeList() {
  const [filter] = useFilter();
  const {listKey} = useSelector((state) => state.common);

  const data = useSubscribeQuery([filter, listKey || 'unknown']);

  return data;
}
