import {Pagination} from 'antd';
import React from 'react';
import useFilter from '../hooks/useFilter';
import useSubscribeList from '../hooks/useSubscribeList';
import './styles.less';

export default function Pagination_() {
  const [filter, setFilter] = useFilter();

  const data = useSubscribeList();
  return (
    <Pagination
      className='pagination'
      total={data?.data?.total || 1}
      current={+filter?.page}
      pageSize={+filter?.size}
      onChange={(page, size) => {
        setFilter({
          ...filter,
          page,
          size,
        });
      }}
      showSizeChanger
    />
  );
}
