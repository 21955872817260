import MiniSidebar from './MiniSidebar';
import DrawerLayout from './DrawerLayout';
import Standard from './Standard';
import BitBucket from './BitBucket';
import Default from './Default';
import HeaderUserLayout from './UserHeader';
import HeaderUserMiniLayout from './UserMiniHeader';
import MiniSidebarToggle from './MiniSidebarToggle';
import {NavStyle} from '../../../shared/constants/AppEnums';

const Layouts = {
  [NavStyle.STANDARD]: Standard,
  [NavStyle.DEFAULT]: Default,
  [NavStyle.HEADER_USER]: HeaderUserLayout,
  [NavStyle.HEADER_USER_MINI]: HeaderUserMiniLayout,
  [NavStyle.MINI_SIDEBAR_TOGGLE]: MiniSidebarToggle,
  [NavStyle.MINI]: MiniSidebar,
  [NavStyle.DRAWER]: DrawerLayout,
  [NavStyle.BIT_BUCKET]: BitBucket,
};
export default Layouts;
