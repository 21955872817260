import {
  RESEND_VERIFY_EMAIL_URL,
  SEND_FORGOT_PASSWORD_URL,
  SEND_RESET_PASSWORD_URL,
  SIGNIN_URL,
  SIGNUP_URL,
  SUBMIT_INFOR_URL,
  URL_REFRESH_TOKEN,
  VERIFY_EMAIL_URL,
} from 'constants/api';
import axiosClient from './axiosClient';

const authApi = {
  signIn(payload) {
    return axiosClient.post(SIGNIN_URL, payload);
  },
  refresh(payload) {
    return axiosClient.post(URL_REFRESH_TOKEN, payload);
  },
  signUp(payload) {
    return axiosClient.post(SIGNUP_URL, payload);
  },
  completeInfo(payload) {
    return axiosClient.post(SUBMIT_INFOR_URL, payload);
  },
  sendForgotPassword(params) {
    return axiosClient.get(SEND_FORGOT_PASSWORD_URL, {params});
  },
  sendResetPassword(payload) {
    const url = `${SEND_RESET_PASSWORD_URL}`;
    return axiosClient.post(url, payload);
  },
  verifyEmail(email, code) {
    return axiosClient.post(VERIFY_EMAIL_URL, undefined, {
      params: {code, email},
    });
  },
  resendVerifyEmail(email) {
    return axiosClient.get(RESEND_VERIFY_EMAIL_URL, {params: {email}});
  },
  // logout(payload: string) {
  // 	return axiosClient.delete(LOGOUT_URL, { data: { refreshToken: payload } });
  // }
};

export default authApi;
