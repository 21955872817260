import {useQuery, useQueryClient} from '@tanstack/react-query';
import {useFilter} from 'components/table';
import {intersection, isEqual} from 'lodash';
import {useSelector} from 'react-redux';

export function useSubscribeQuery(key, tryToGetPreviousData = false) {
  const queryClient = useQueryClient();
  const cache = queryClient.getQueryCache();
  const result = cache?.find(key)?.state?.data;
  if (tryToGetPreviousData && !result && cache) {
    const all = cache.getAll();
    const reversedArr = all.map((_, i) => all[all.length - i - 1]);
    const theSameElementOnKey = reversedArr.find((item) => {
      const intersectionArr = intersection(item.queryKey, key);
      return intersectionArr.length > 0 && !isEqual(key, item.queryKey);
    });
    const result = theSameElementOnKey?.state?.data;
    if (result) {
      return result;
    }
  }
  return result;
}

export const useRefetchList = () => {
  const {listKey} = useSelector((state) => state.common);
  const query = useQueryClient();
  return () => query.refetchQueries(listKey);
};

export const useRefetch = () => {
  const query = useQueryClient();
  return query.refetchQueries.bind(query);
};

export const useFetch = ({
  api,
  withFilter = true,
  queryFn,
  queryKeyArrWithFilter,
  queryKeyArr,
  moreParams,
  defaultFilter,
  cacheTime,
  staleTime,
}) => {
  const [filter] = useFilter({defaultFilter});

  const getQueryKey = () => {
    if (queryKeyArrWithFilter) return [filter, ...queryKeyArrWithFilter];
    if (queryKeyArr) return queryKeyArr;
    if (filter) {
      return [filter];
    }
    return [];
  };

  const getQueryFn = () => {
    if (queryFn) return queryFn;
    if (withFilter)
      return () => {
        return api({
          ...filter,
          ...moreParams,
        });
      };
    if (!withFilter)
      return () => {
        return api({
          ...moreParams,
        });
      };
  };

  return useQuery({
    queryKey: getQueryKey(),
    queryFn: getQueryFn(),
    cacheTime: cacheTime,
    staleTime: staleTime,
    keepPreviousData: true,
  });
};

export const useFetchSelects = ({key, ...rest}) => {
  return useFetch({withFilter: false, queryKeyArr: [key], ...rest});
};

export const useFetchSimple = ({key, ...rest}) => {
  return useFetch({withFilter: false, queryKeyArr: [key], ...rest});
};
