export const DATE_FORMAT = 'DD/MM/YYYY';
export const PDHT_SCROLL = {
  x: 1200,
};
export const DRAFT = 'DRAFT';
export const TIME_FORMAT = 'HH:mm';

export const CHEEK = 'CHEEK';
export const CLASS_NAME_TAB_ANSWERS =
  'p-4 xl:p-5 w-full mx-auto rounded-xl my-shadow active-border z-10 relative flex flex-col justify-center items-start mt-5 pb-16 lg:pb-5 relative';
