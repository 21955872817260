import {ExclamationCircleOutlined} from '@ant-design/icons';
import {Modal, message} from 'antd';

export const confirmDelete = ({
  api,
  record,
  contentKey = 'title' || 'content',
  valueKey = 'id',
  successCallback,
}) => {
  Modal.confirm({
    title: 'Bạn chắc chắn muốn xóa chứ?',
    icon: <ExclamationCircleOutlined />,
    content: record[contentKey],
    okText: 'Ok',
    cancelText: 'Hủy',
    onOk: async () => {
      try {
        if (api) {
          await api(record[valueKey]);
        }
        if (successCallback) {
          successCallback();
        }
        message.success('Xóa thành công');
      } catch (e) {
        console.log('e :', e);
      }
    },
  });
};
