import ImgCrop from 'antd-img-crop';
import {AppAvatar} from 'components/tiny';
import {ImageFormGeneral} from './ImageFormGeneral';
import {ImageUpload} from './ImageUpload';

export const FormAvatar = ({
  onChange,
  value,
  typeAvatar,
  size,
  disabled,
  ...rest
}) => {
  return (
    <ImageFormGeneral
      onChange={onChange}
      value={value}
      disableCenteredBtn={disabled}
      {...rest}>
      <FormAvatar_ typeAvatar={typeAvatar} size={size} />
    </ImageFormGeneral>
  );
};

const FormAvatar_ = ({
  handleFileChange,
  typeAvatar,
  size,
  value,
  editIcon,
  className,
}) => {
  const uploadProps = {
    name: 'avatar',
    showUploadList: false,
    beforeUpload: (file) => {
      handleFileChange(file);
      return false;
    },
  };

  return (
    <>
      <AppAvatar
        type={typeAvatar}
        src={value?.src || value}
        size={size}
        className={className}
      />
      <ImgCrop rotate>
        <ImageUpload {...uploadProps}>{editIcon}</ImageUpload>
      </ImgCrop>
    </>
  );
};
