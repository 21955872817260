import {LIST_NEWS_URL, UPLOAD_URL} from '../constants/api';
import axiosClient from './axiosClient';

export const commonApi = {
  upload(file) {
    const formData = new FormData();
    formData.append('file', file);
    return axiosClient.post(UPLOAD_URL, formData);
  },
  getBlogs() {
    return axiosClient.get(LIST_NEWS_URL, {params: {page: 1, size: 10}});
  },
};
