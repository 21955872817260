import {Button, Form, message, Space} from 'antd';
import {useRefetchList} from 'hooks';
import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import {GeneralModal} from '..';
import {modalActions} from '../slice';
import {breakPoints} from 'global/responsive';

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 0;
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

const StyledSpace = styled(Space)`
  @media ${breakPoints.xs} {
    /* Css to full width for button submit */
    flex-grow: 1;
    .ant-space-item {
      flex-grow: 1;

      button[type='submit'] {
        width: 100%;
      }
    }
  }
`;
export const Modal = ({
  form,
  children,
  title,
  titleTopic,
  width,
  onFinish,
  initialValues = () => {},
  noCloseModalWhenSuccess = false,
  footerBtn,
  catchCallback,
  onCancel,
  disabled = false,
}) => {
  const {isOpen, type, record} = useSelector((state) => state.modal);
  const refetchList = useRefetchList();
  const dispatch = useDispatch();

  const [loadingSubmitBtn, setLoadingSubmitBtn] = useState(false);

  useEffect(() => {
    if (isOpen && record) {
      form.setFieldsValue({...record, ...initialValues(record)});
    }
    if (!isOpen) {
      form.resetFields();
    }
  }, [isOpen]);

  const getTitle = () => {
    if (title) return title;
    if (titleTopic) {
      const titleTopicLower = titleTopic.toLowerCase();
      if (type === 'add') return `Tạo ${titleTopicLower}`;
      if (type === 'edit') return `Sửa ${titleTopicLower}`;
    }
    return undefined;
  };

  const handleCancel = () => {
    dispatch(modalActions.closeModal());
  };

  const handleFinish = async (values) => {
    setLoadingSubmitBtn(true);
    try {
      await onFinish(values);
      if (type === 'add') message.success('Tạo thành công');
      if (type === 'edit') message.success('Sửa thành công');
      refetchList();
      if (!noCloseModalWhenSuccess) {
        handleCancel();
      }
    } catch (e) {
      console.log(e);
      if (catchCallback) {
        catchCallback(e);
      }
    }
    setLoadingSubmitBtn(false);
  };

  return (
    <GeneralModal
      title={getTitle()}
      open={isOpen}
      footer={null}
      width={width}
      form={form}
      onFinish={handleFinish}
      onCancel={onCancel}>
      {children}
      <Footer>
        <StyledSpace>
          {footerBtn ? (
            <StyledFormItem>{footerBtn}</StyledFormItem>
          ) : (
            <StyledFormItem>
              <Button
                type='primary'
                htmlType='submit'
                loading={loadingSubmitBtn}
                disabled={disabled}>
                Lưu
              </Button>
            </StyledFormItem>
          )}
        </StyledSpace>
      </Footer>
    </GeneralModal>
  );
};

Modal.propTypes = {
  form: PropTypes.object,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  titleTopic: PropTypes.string,
  children: PropTypes.element,
  onFinish: PropTypes.func,
  finishCallback: PropTypes.func,
  catchCallback: PropTypes.func,
};
