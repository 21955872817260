import {defaultParams} from 'constants/common';
import {createSearchParams, useSearchParams} from 'react-router-dom';
import {mapValues} from 'lodash';
import {tryToConvertToNumber} from 'utils';
import {useMemo} from 'react';

export default function useFilter({defaultFilter = {}} = {}) {
  const [urlParams, setUrlParams] = useSearchParams({
    ...defaultParams,
    ...defaultFilter,
  });

  const filter = useMemo(
    () =>
      mapValues(Object.fromEntries([...urlParams]), (value) => {
        return tryToConvertToNumber(value);
      }),
    [urlParams],
  );
  
  const setFilter = (
    newFilter,
    options = {
      replace: true,
    },
  ) => {
    const params = createSearchParams(newFilter);
    setUrlParams(params);
  };

  return [filter, setFilter];
}
