import {themeSelector} from 'global/themeUtils';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  height: 92%;
  padding: 0 20px;
  margin: auto 0;
  border-left: 3px solid ${themeSelector.green};
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const Title = styled.div`
  font-weight: 600;
  font-size: clamp(14px, 14px + 0.2vw, 18px);
  margin-bottom: 7px;
`;
const Content = styled.div`
  font-size: clamp(10px, 10px + 0.2vw, 14px);
  margin-top: 2px;
`;

export const HeaderDoQuiz = ({data}) => {
  return (
    <Container>
      <Title>{data?.title}</Title>
      <Content>{data?.content}</Content>
    </Container>
  );
};
