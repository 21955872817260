import {Upload, message} from 'antd';
import React from 'react';

export const ImageUpload = ({children, beforeUpload, ...rest}) => {
  const handleBeforeUpload = (file) => {
    // Check size
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      message.error('Dung lượng của ảnh không được lớn hơn 5mb');
      return false;
    }
    beforeUpload(file);
  };

  return (
    <Upload
      beforeUpload={handleBeforeUpload}
      accept='image/png, image/gif, image/jpeg'
      multiple={true}
      {...rest}>
      {children}
    </Upload>
  );
};
