import {cookieKey} from 'constants/auth';
import Cookies from 'js-cookie';

export const convertRoleFromBackend = (roles) => {
  return roles?.map((item) => item?.authority);
};

export const getPermissionsFromLocal = () => {
  const userInfoJson = Cookies.get(cookieKey.userInfo);
  return userInfoJson
    ? convertRoleFromBackend(JSON.parse(userInfoJson)?.role)
    : undefined;
};

export const isAllowedRoute = (userRole, route) => {
  if (route?.permissionKey === undefined) return true;
  return userRole?.includes(route?.permissionKey);
};
