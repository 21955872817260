import {Button} from 'antd';
import React from 'react';
import {useIntl} from 'react-intl';
import PropTypes from 'prop-types';
import {useRefetchList} from 'hooks';
import {confirmDelete} from 'utils/antd';
import {DeleteOutlined} from '@ant-design/icons';

export function DeleteBtn(props) {
  const refetch = useRefetchList();
  const confirm = () => confirmDelete({...props, successCallback: refetch});

  const {messages} = useIntl();
  return (
    <Button
      danger
      onClick={confirm}
      icon={<DeleteOutlined />}
      disabled={props?.disabled}>
      {messages['common.delete']}
    </Button>
  );
}

DeleteBtn.propTypes = {
  api: PropTypes.func.isRequired,
  record: PropTypes.object.isRequired,
  contentKey: PropTypes.string.isRequired,
  valueKey: PropTypes.string.isRequired,
};
