/* eslint-disable react/prop-types */
import {List} from 'antd';
import classNames from 'classnames';
import {AppAvatar} from 'components/tiny';
import PropTypes from 'prop-types';
import './index.style.less';

const ProfileListItem = ({item, onClickToItem, isActive}) => {
  return (
    <List.Item
      className={classNames('appointment-cell item-hover', {
        'item-active': isActive,
      })}
      onClick={() => onClickToItem(item)}
      style={{cursor: 'pointer'}}>
      <AppAvatar
        className='appointment-cell-avatar'
        src={item?.imageUrl}
        size={48}
      />
      <div className='appointment-cell-content'>
        <h5>{item?.name}</h5>
        <p>{item?.userNameCreatedBy}</p>
      </div>
      <div className='appointment-cell-action'>
        <p className='appointment-cell-time'>{item?.relationText}</p>
      </div>
    </List.Item>
  );
};

ProfileListItem.propTypes = {
  item: PropTypes.object.isRequired,
};

export const ProfileListItemManage = ({onChange, value, data}) => {
  const handleClickToItem = (item) => {
    onChange(item?.profileId);
  };
  return (
    <>
      {data?.map((item) => {
        return (
          <ProfileListItem
            key={item.id}
            item={item}
            onClickToItem={handleClickToItem}
            isActive={item?.profileId === value}
          />
        );
      })}
    </>
  );
};
