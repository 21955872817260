import {Menu} from 'antd';
import {authorizedDiscStructure} from 'pages/discRoute';
import React from 'react';
import {useIntl} from 'react-intl';
import {Link, useLocation} from 'react-router-dom';
import {getPermissionsFromLocal, isAllowedRoute} from 'utils/authUtils';
import sidebarConfig from '../../pages/sidebarConfig';
import {useSidebarContext} from './AppContextProvider/SidebarContextProvider';

function getStyles(item, sidebarColorSet, isSidebarBgImage, index, isGroup) {
  const {pathname} = useLocation();
  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split('/');

  if (index === 0 || isGroup) {
    return {
      color: sidebarColorSet.sidebarTextColor,
      backgroundColor: isSidebarBgImage ? '' : sidebarColorSet.sidebarBgColor,
    };
  } else {
    const isActive = (defaultOpenKeys[index] || defaultOpenKeys[0]) === item.id;

    return {
      color: isActive
        ? sidebarColorSet.sidebarMenuSelectedTextColor
        : sidebarColorSet.sidebarTextColor,
      backgroundColor: isActive
        ? sidebarColorSet.sidebarMenuSelectedBgColor
        : isSidebarBgImage
        ? ''
        : sidebarColorSet.sidebarBgColor,
    };
  }
}

const renderMenuItemChildren = (item) => {
  const {icon, messageId, path} = item;
  const {messages} = useIntl();

  if (path && path.includes('/'))
    return (
      <Link to={path}>
        {icon &&
          (React.isValidElement(icon) ? (
            <span className='ant-menu-item-icon'>{icon}</span>
          ) : (
            <icon className='ant-menu-item-icon' />
          ))}
        <span data-testid={messageId.toLowerCase + '-nav'}>
          {messages[messageId]}
        </span>
      </Link>
    );
  else {
    return (
      <>
        {icon &&
          (React.isValidElement(icon) ? (
            <span className='ant-menu-item-icon'>{icon}</span>
          ) : (
            <icon className='ant-menu-item-icon' />
          ))}
        <span data-testid={messageId.toLowerCase + '-nav'}>
          {messages[messageId]}
        </span>
      </>
    );
  }
};

const renderMenuItem = (item, sidebarColorSet, isSidebarBgImage, index) => {
  const userRole = getPermissionsFromLocal();
  const isShow = isShowSidebarItem(userRole, item);

  if (!isShow) return null;

  return item.type === 'collapse' ? (
    <Menu.SubMenu
      className='menu-collapsed'
      style={getStyles(item, sidebarColorSet, isSidebarBgImage, index, true)}
      key={item.path ? item.path : item.id}
      title={renderMenuItemChildren(item, sidebarColorSet, isSidebarBgImage)}>
      {item.children.map((item) =>
        renderMenuItem(item, sidebarColorSet, isSidebarBgImage, index + 1),
      )}
    </Menu.SubMenu>
  ) : (
    <Menu.Item
      key={item.id}
      style={getStyles(item, sidebarColorSet, isSidebarBgImage, index)}>
      {item.children
        ? item.children
        : renderMenuItemChildren(item, sidebarColorSet, isSidebarBgImage)}
    </Menu.Item>
  );
};

const renderMenu = (item, sidebarColorSet, isSidebarBgImage, index) => {
  if (item.type === 'group') {
    return (
      <Menu.ItemGroup
        style={getStyles(item, sidebarColorSet, isSidebarBgImage, index, true)}
        key={item.path ? item.path : item.id}
        title={renderMenuItemChildren(item, sidebarColorSet, isSidebarBgImage)}>
        {item.children.map((item) =>
          renderMenuItem(item, sidebarColorSet, isSidebarBgImage, index + 1),
        )}
      </Menu.ItemGroup>
    );
  } else if (item.type === 'collapse') {
    return (
      <Menu.SubMenu
        className='menu-collapse'
        style={getStyles(item, sidebarColorSet, isSidebarBgImage, index, true)}
        key={item.path ? item.path : item.id}
        title={renderMenuItemChildren(item, sidebarColorSet, isSidebarBgImage)}>
        {item.children.map((item) =>
          renderMenuItem(item, sidebarColorSet, isSidebarBgImage, index + 1),
        )}
      </Menu.SubMenu>
    );
  } else {
    return (
      <Menu.Item
        key={item.id}
        exact={item.exact}
        style={getStyles(item, sidebarColorSet, isSidebarBgImage, index)}>
        {item.children
          ? item.children
          : renderMenuItemChildren(
              item,
              sidebarColorSet,
              isSidebarBgImage,
              index,
            )}
      </Menu.Item>
    );
  }
};

const isShowSidebarItem = (userRole, sidebarItem) => {
  const path = sidebarItem?.path;
  // if (!path) return true;
  const route = authorizedDiscStructure.find((item) => item?.path === path);
  if (!isAllowedRoute(userRole, route)) return false;
  return true;
};

export const getRouteMenus = () => {
  const userRole = getPermissionsFromLocal();
  const {sidebarColorSet} = useSidebarContext();
  const {isSidebarBgImage} = useSidebarContext();

  return sidebarConfig.map((route) => {
    const isShow = isShowSidebarItem(userRole, route);
    const isShowParent = userRole?.includes(route?.role);
    if (!isShow || !isShowParent) return null;
    return renderMenu(route, sidebarColorSet, isSidebarBgImage, 0);
  });
};
