import {useDispatch} from 'react-redux';
import {modalActions} from './slice';

export const useEditModal = () => {
  const dispatch = useDispatch();
  const handleEdit = (record) => {
    dispatch(modalActions.openModal());
    dispatch(modalActions.setType('edit'));
    dispatch(modalActions.setRecord(record));
  };
  return handleEdit;
};

export const useOpenWithRecordModal = () => {
  const dispatch = useDispatch();
  const handleOpen = (record) => {
    dispatch(modalActions.openModal());
    dispatch(modalActions.setRecord(record));
  };
  return handleOpen;
};

export const useAddModal = () => {
  const dispatch = useDispatch();
  const handleAdd = () => {
    dispatch(modalActions.openModal());
    dispatch(modalActions.setType('add'));
    dispatch(modalActions.setRecord(undefined));
  };
  return handleAdd;
};
