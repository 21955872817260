import {RangePicker} from 'components/tiny';
import React from 'react';
import useFilter from '../hooks/useFilter';
import moment from 'moment';
import {SearchOutlined} from '@ant-design/icons';

export const FilterRangePicker = ({
  name = ['startDate', 'endDate'],
  ...rest
}) => {
  const [startName, endName] = name;
  const [filter, setFilter] = useFilter();

  const start = filter?.[startName];
  const end = filter?.[endName];

  const value = [
    start ? moment.unix(start) : undefined,
    end ? moment.unix(end) : undefined,
  ];

  const handleOnChange = (e) => {
    if (!e) {
      delete filter?.[startName];
      delete filter?.[endName];
      setFilter({
        ...filter,
      });
    } else {
      const [start, end] = e;
      if (start && end) {
        setFilter({
          ...filter,
          [startName]: start.unix(),
          [endName]: end.unix(),
        });
      }
    }
  };

  return (
    <RangePicker
      value={value}
      onChange={handleOnChange}
      suffixIcon={<SearchOutlined />}
      {...rest}
    />
  );
};
