import {configureStore} from '@reduxjs/toolkit';
import {modalReducer} from 'components/modal';
import commonReducer from '../slice/common';
import homeQuizReducer from '../slice/homeQuiz';
import listJobsReducer from '../../pages/homeQuiz/components/slice/listJob';
import UserInfoReducer from '../../pages/profile/UserProfile/slice/infoUser';
import listQuizzesReducer from 'pages/homeQuiz/components/slice/listQuiz';
export const store = configureStore({
  reducer: {
    common: commonReducer,
    modal: modalReducer,
    homeQuiz: homeQuizReducer,
    listJobs: listJobsReducer,
    userInfo: UserInfoReducer,
    listQuizzes: listQuizzesReducer,
  },
});
