/* eslint-disable react/prop-types */
import {
  useFetchProfileRelation,
  useFetchQuizzes,
  useFetchQuizzesForAssignmentFilter,
  useFetchQuizzesForFilter,
  useFetchQuizzesForReportFilter,
} from 'hooks';
import React from 'react';
import {GeneralSelect} from '.';
import {serviceButtonTypesForSelect} from 'constants/service';

export const ProfileRelationSelect = ({...rest}) => {
  const {data} = useFetchProfileRelation();

  return (
    <GeneralSelect
      {...rest}
      data={data?.data}
      displayKey='value'
      valueKey='key'
    />
  );
};

export const AllQuizzesSelect = ({...rest}) => {
  const {data} = useFetchQuizzes();
  return (
    <GeneralSelect
      {...rest}
      data={data?.data?.resource}
      displayKey='nameQuiz'
      valueKey='quizId'
    />
  );
};

export const AllQuizzesForAssignmentFilterSelect = ({...rest}) => {
  const {data} = useFetchQuizzesForAssignmentFilter();
  return (
    <GeneralSelect
      {...rest}
      data={data?.data}
      displayKey='title'
      valueKey='id'
    />
  );
};
export const AllQuizzesForReportFilterSelect = ({...rest}) => {
  const {data} = useFetchQuizzesForReportFilter();
  return (
    <GeneralSelect
      {...rest}
      data={data?.data}
      displayKey='value'
      valueKey='value'
    />
  );
};

export const ServiceButtonTypeSelect = ({...rest}) => {
  return (
    <GeneralSelect
      {...rest}
      data={serviceButtonTypesForSelect}
      displayKey='title'
      valueKey='value'
    />
  );
};
