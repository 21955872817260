import enMessages from '../locales/vi.json';
import vi_VN from 'antd/lib/locale/vi_VN';

const viLang = {
  messages: {
    ...enMessages,
  },
  antLocale: vi_VN,
  locale: 'vi',
};
export default viLang;
