export const SIGNIN_URL = 'admin/login';
export const SIGNUP_URL = '/register';
export const HISTORY_URL = '/admin/history';
export const USER_URL = '/admin/users';
export const HOME_URL = '/user/quizzes';
export const ADDQUESTION_URL = '/admin/quizzes/user';
export const MY_QUIZ_URL = '/admin/quizzes/user';
export const CHECK_TOKEN_URL = '/users/information';
export const SUBMIT_INFOR_URL = '/users/submit/information';
export const UPLOAD_URL = '/admin/upload';
export const QUIZ_URL = '/admin/quiz';
export const ADMIN_RESULT_URL = '/admin/result';
export const URL_QUIZ = '/user/result';
export const GET_UUID_ULR = '/user/quiz';
export const HISTORY_RESULT_ULR = '/admin/history/result';
export const HISTORY_LIST_HISTORY_USER = '/user/history';
export const URL_COMPLETE = '/user/quiz/completed';
export const URL_GET_TOKEN_LOGIN = '/firebase/login';
export const URL_REFRESH_TOKEN = '/refreshToken';
export const FETCH_QUESTION_URL = '/admin/quizzes';
export const UPDATE_QUESTION_URL = '/admin/quizzes';
export const ALL_QUIZ_URL = '/admin/quizzes';
export const DELETE_QUESTIONS_URL = '/admin/questions';
export const DELETE_ANSWERS_URL = '/admin/answers';
export const FETCH_QUESTION_TYPE_URL = '/admin/question-types';
export const FETCH_QUESTION_SIMPLE_URL = '/admin/question-simples';
export const FETCH_QUESTION_COMPLEX_URL = '/admin/question-complexes';
export const GET_KEYS_ANSWERS = 'admin/answer/keys';
export const PROFILE_URL = 'user/profiles';
export const PROFILE_RELATION_URL = 'user/profiles/relations';
export const DOWNLOAD_REPORT_DISC = 'user/report/download';
export const UPLOAD_REPORT_URL = 'user/report/upload';
export const REPORT_URL = 'user/report';
export const CONFIG_USER_URL = '/user/config';
export const API_LIST_JOBS = '/jobs';
export const SEND_FORGOT_PASSWORD_URL = '/user/forgot-password';
export const SEND_RESET_PASSWORD_URL = '/user/reset-password';
export const PUT_USER_PERMISSIONS_URL = '/users';
export const USER_INFOR_URL = '/users/me';
export const CHANGE_PASSWORD_URL = '/users/change-password';
export const CHECK_OLD_PASSWORD_URL = '/user/check-password';
export const VERIFY_EMAIL_URL = '/user/verify';
export const RESEND_VERIFY_EMAIL_URL = '/user/resend-verify';
export const ALL_QUIZZES_FOR_ASSIGNMENT_FILTER = '/admin/history/services';
export const ALL_QUIZZES_FOR_REPORT_FILTER = '/report/services';
export const SERVICE_USER_URL = '/user/category';
export const SERVICE_ADMIN_URL = '/admin/category';
export const CHECK_TOKEN = '/public/check-token';
export const LIST_BOUGHT_URL = '/user/product';
export const LIST_ROLE_URL = 'admin/role';
export const LIST_PERMISSIONS_URL = 'admin/permissions';
export const LIST_NEWS_URL = 'news';
