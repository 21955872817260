import {
  API_LIST_JOBS,
  GET_UUID_ULR,
  HOME_URL,
  URL_QUIZ,
} from '../constants/api';
import axiosClient from './axiosClient';

export const HomeApi = {
  get(params) {
    return axiosClient.get(HOME_URL, {params});
  },
  getUuid(id, profileId) {
    const url = `${GET_UUID_ULR}/${id}/start`;
    return axiosClient.get(url, {
      params: {
        profileId,
      },
    });
  },
  getquizid(id) {
    const url = `${GET_UUID_ULR}/${id}`;
    return axiosClient.get(url);
  },
  getQuiz(params) {
    if (params?.page === 0) params.page = 1;
    const url = `${URL_QUIZ}/${params?.resultUuid}/show`;
    return axiosClient.get(url, {params});
  },
  getProfileDetail(uuid, params) {
    const url = `${URL_QUIZ}/${uuid}/profile`;
    return axiosClient.get(url, {params});
  },
  sendQuiz(id, payload) {
    const url = `${URL_QUIZ}/${id}/submit`;
    return axiosClient.post(url, payload);
  },
  listJobs(params) {
    const url = `${API_LIST_JOBS}`;
    return axiosClient.get(url, {params});
  },
};
