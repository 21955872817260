import {Grid, Layout} from 'antd';
import axiosClient from 'api/axiosClient';
import {CHECK_TOKEN} from 'constants/api';
import {cookieKey} from 'constants/auth';
import Cookies from 'js-cookie';
import {authorizedDiscStructure} from 'pages/discRoute';
import {useEffect, useLayoutEffect} from 'react';
import {useDispatch} from 'react-redux';
import {Navigate, Route, Routes, useLocation} from 'react-router-dom';
import {commonActions} from 'redux/slice/common';
import {
  anonymousStructure,
  authorizedStructure,
  unAuthorizedStructure,
} from '../../../pages';
import {initialUrl} from '../../../shared/constants/AppConst';
import {AppSuspense} from '../../index';
import {useAuthMethod, useAuthUser} from '../../utility/AuthHooks';
import generateRoutes from '../../utility/RouteGenerator';
import AppErrorBoundary from '../AppErrorBoundary';
import './index.style.less';
const {Content} = Layout;
const {useBreakpoint} = Grid;

const AppContentView = () => {
  const {pathname, search} = useLocation();
  const {user, isAuthenticated} = useAuthUser();
  const {logout} = useAuthMethod();
  const width = useBreakpoint();
  const dispatch = useDispatch();
  const urlParamsTestNow = new URLSearchParams(search);

  const startIndex = search.indexOf('token=');
  const endIndex = search.indexOf('&', startIndex);

  const tokenSubstring = decodeURIComponent(
    search.substring(startIndex + 6, endIndex),
  );
  const callback = decodeURIComponent(urlParamsTestNow.get('callback_url'));
  const redirectURL = decodeURIComponent(urlParamsTestNow.get('redirect_url'));
  const isLogout = redirectURL.includes('/logout');
  const element = generateRoutes({
    isAuthenticated: isAuthenticated,
    userRole: user?.role,
    unAuthorizedStructure,
    authorizedStructure,
    anonymousStructure,
  });

  useEffect(async () => {
    if (pathname === '/route') {
      const startIndex = redirectURL.indexOf('/route');
      try {
        const {data} = await axiosClient.get(CHECK_TOKEN, {
          params: {token: tokenSubstring},
        });
        Cookies.set(cookieKey.tokenWP, tokenSubstring);
        Cookies.set(cookieKey.token, data?.token);
        Cookies.set(cookieKey.userInfo, JSON.stringify({...data}));

        if (isLogout) {
          await logout();
        } else {
          window.location.href = redirectURL.substring(startIndex);
        }
      } catch (e) {
        window.location = callback;
      }
    }
  }, []);
  // Add remember tried link of user
  useLayoutEffect(() => {
    const linkWantToSaveAsTriedLink = authorizedDiscStructure
      .filter((item) => item?.saveAsTriedLink)
      ?.map((item) => item?.path);

    const subSttPathname = pathname?.match(/^\/(\w+)\//)?.[1];
    if (
      !isAuthenticated &&
      pathname &&
      pathname !== '/' &&
      linkWantToSaveAsTriedLink?.find((str) => str?.includes(subSttPathname))
    ) {
      localStorage.setItem('triedLink', pathname);
    }
  }, []);

  return (
    <Content
      className='main-content-view'
      onClick={() =>
        width?.xl ? null : dispatch(commonActions.setIsCollapsed(true))
      }>
      <AppSuspense>
        <AppErrorBoundary>
          {pathname === '/route' ? (
            <Navigate to={pathname} replace />
          ) : (
            <>
              {element}
              <Routes>
                <Route
                  path='/'
                  element={<Navigate to={initialUrl} replace />}
                />
                <Route path='*' element={<span />} />
              </Routes>
            </>
          )}
        </AppErrorBoundary>
      </AppSuspense>
    </Content>
  );
};

export default AppContentView;
