/* eslint-disable react/prop-types */
import AppCard from '@crema/core/AppCard';
import avatarDefault from 'assets/images/avatar-default.jpg';
import {themeSelector} from 'global/themeUtils';
import styled, {keyframes} from 'styled-components';

const AvatarImage = styled.img`
	width: ${(props) => (props.size ? `${props.size}px` : '100%')};
	aspect-ratio: 1;
	object-fit: cover;
	border-radius: ${(props) => (props.type === 'circle' ? '50%' : '10%')};
`;

export const AppAvatar = ({
  src,
  size,
  defaultContent,
  type = 'circle',
  ...rest
}) => {
  return (
    <AvatarImage {...rest} src={src || avatarDefault} size={size} type={type} />
  );
};

const borderToRight = keyframes`
	0% {
		width: 0;
	}

	100% {
		width: 100%;
	}
`;

const StyledCard = styled(AppCard)`
	.ant-card-head {
		padding-left: 0px !important;
		min-height: 56px;

		.card-table-title {
			min-width: 130px;
			margin-left: 20px !important;
			margin-right: 10px;
			display: inline-block;
			width: fit-content;
			color: ${themeSelector.primary};

			&-border {
				height: 5px;
				border-top-right-radius: 4px;
				border-bottom-right-radius: 4px;
				background-color: ${themeSelector.primary};
				animation: ${borderToRight} 0.3s linear both;
			}
		}
	}
`;

export const PageCard = ({title, moreTitle, ...rest}) => {
  return (
    <StyledCard
      {...rest}
      title={
        <>
          <div className='card-table-title'>
            {title}
            <div className='card-table-title-border'></div>
          </div>
          {moreTitle}
        </>
      }
    />
  );
};
