import {
  DatePicker as AntdDatePicker,
  Input as AntdInput,
  Select as AntdSelect,
} from 'antd';
import {timeFormat} from 'constants/common';
import {forwardRef} from 'react';
import styled from 'styled-components';

export const Input = forwardRef(({...rest}, ref) => {
  return <AntdInput ref={ref} allowClear {...rest} />;
});

const StyledRangePicker = styled(AntdDatePicker.RangePicker)`
  width: 100%;
`;

export const RangePicker = ({...rest}) => {
  return (
    <StyledRangePicker
      format={timeFormat.vnFullDate}
      placeholder={['Bắt đầu', 'Kết thúc']}
      {...rest}
    />
  );
};

const StyledSelect = styled(AntdSelect)`
  width: 100%;
`;

export const Select = ({...rest}) => {
  return <StyledSelect allowClear {...rest} />;
};

const StyledTextArea = styled(AntdInput.TextArea)`
  width: 100%;
`;

export const TextArea = ({...rest}) => {
  return <StyledTextArea allowClear autoSize={{minRows: 4}} {...rest} />;
};
