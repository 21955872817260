import {Provider} from 'react-redux';
import './shared/styles/crema.less';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
import {BrowserRouter} from 'react-router-dom';
import {store} from 'redux/store';
import {
  AppContextProvider,
  AppLayout,
  AppLocaleProvider,
  AppThemeProvider,
  AuthRoutes,
} from './@crema';
import FirebaseAuthProvider from './@crema/services/auth/firebase/FirebaseAuthProvider';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {
  return (
    <AppContextProvider>
      <Provider store={store}>
        <AppThemeProvider>
          <AppLocaleProvider>
            <BrowserRouter>
              <QueryClientProvider client={queryClient}>
                <FirebaseAuthProvider>
                  <AuthRoutes>
                    <AppLayout />
                    <ReactQueryDevtools initialIsOpen={false} />
                  </AuthRoutes>
                </FirebaseAuthProvider>
              </QueryClientProvider>
            </BrowserRouter>
          </AppLocaleProvider>
        </AppThemeProvider>
      </Provider>
    </AppContextProvider>
  );
};

export default App;
