import {message} from 'antd';
import {HomeApi} from 'api/home';
import {modalActions} from 'components/modal';
import {setProfileId, setQuizStart} from 'redux/slice/homeQuiz';

export const handleStartQuiz = async (
  quizStart,
  profileId,
  dispatch,
  navigate,
) => {
  if (!profileId) {
    message.error('Bạn vui lòng chọn hồ sơ');
    return 'failed';
  }
  try {
    const resp = await HomeApi.getUuid(quizStart?.codeProduct, profileId);
    const uuid = resp.data.resultUuid;
    dispatch(setProfileId(profileId));
    dispatch(setQuizStart(quizStart));
    dispatch(modalActions.closeModal());
    navigate(`/quiz/start/${uuid}/${quizStart?.questionComplex}`);
  } catch (error) {
    message.error(error?.response?.data?.message);
  }
};
