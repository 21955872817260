import {EditOutlined} from '@ant-design/icons';
import {breakPoints} from 'global/responsive';
import React from 'react';
import styled from 'styled-components';

const AvatarWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: inline-block;
  cursor: pointer;
`;

const getIcon = (Icon = EditOutlined) => {
  return styled(Icon)`
    color: #ce9b25;
    font-size: 16px;
    @media ${breakPoints.md} {
      position: absolute;
      left: 50%;
      top: 50%;
      background-color: #ffffffc3;
      border-radius: 50%;
      padding: 12px;
      cursor: pointer;
      transform: translateX(-50%) translateY(-50%);
    }
  `;
};

export const ImageFormGeneral = ({
  onChange,
  value,
  children,
  disableCenteredBtn,
  CenteredBtn,
  onClickToImage,
  oldImage,
  ...rest
}) => {
  const handleFileChange = (file) => {
    if (file) {
      onChange({src: URL.createObjectURL(file), file});
    }
  };

  const CenteredBtnWithStyled = getIcon(CenteredBtn);

  return (
    <AvatarWrapper onClick={onClickToImage}>
      {React.isValidElement(children) &&
        React.cloneElement(children, {
          handleFileChange,
          editIcon: disableCenteredBtn || <CenteredBtnWithStyled />,
          value,
          ...rest,
        })}
    </AvatarWrapper>
  );
};
