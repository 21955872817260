export const linkTypes = {
  INTERNAL: 'INTERNAL',

  EXTERNAL: 'EXTERNAL',

  DOQUIZ: 'DOQUIZ',

  LISTQUIZ: 'LISTQUIZ',
};
export const SORT = {
  DESC: 'DESC',
  ASC: 'ASC',
};
