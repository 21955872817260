import React from 'react';
import {Layout} from 'antd';
import './index.style.less';
import AppLogo from '../components/AppLogo';
import PropTypes from 'prop-types';
import {AiOutlineMenu} from 'react-icons/ai';
import UserInfo from '../components/UserInfo';
import {ExploreButton, InvisibleHeaderBlock} from '../components/Common';

const AppHeader = ({isCollapsed, onToggleSidebar}) => {
  const {Header} = Layout;

  return (
    <Header className='app-userHeader'>
      <a className='trigger' onClick={() => onToggleSidebar(!isCollapsed)}>
        <AiOutlineMenu />
      </a>
      <AppLogo />
      <ExploreButton />
      <UserInfo />
      <InvisibleHeaderBlock />
    </Header>
  );
};

export default AppHeader;

AppHeader.propTypes = {
  onToggleSidebar: PropTypes.func,
  isCollapsed: PropTypes.bool,
};
