import AppScrollbar from '@crema/core/AppScrollbar';
import {Form} from 'antd';
import PropTypes from 'prop-types';
import {ProfileListItemManage} from './ProfileListItem';
import './index.style.less';

const ProfileListSelect = ({data}) => {
  return (
    <AppScrollbar className='up-coming-appoint-scrollbar'>
      <Form.Item name='profileId'>
        <ProfileListItemManage data={data} />
      </Form.Item>
    </AppScrollbar>
  );
};

export default ProfileListSelect;

ProfileListSelect.defaultProps = {
  data: [],
};

ProfileListSelect.propTypes = {
  data: PropTypes.array,
};
