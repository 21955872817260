import {
  BiAperture,
  BiCard,
  BiListOl,
  BiMessageSquareAdd,
  BiUser,
} from 'react-icons/bi';
import {MdOutlinePersonSearch} from 'react-icons/md';

import {AiOutlineProfile, AiOutlineRadiusSetting} from 'react-icons/ai';
import {IoIosTimer} from 'react-icons/io';
import {MdOutlineAssignment} from 'react-icons/md';
import {TbListCheck, TbReport} from 'react-icons/tb';
import {permissions} from 'constants/auth';

const sidebarConfig = [
  {
    id: 'home',
    title: 'Application',
    messageId: 'sidebar.home',
    type: 'group',
    role: 'HOME_PAGE',
    children: [
      {
        id: 'homeQuiz',
        title: 'HomeQuiz',
        icon: <TbListCheck color='#288D9B' />,
        messageId: 'sidebar.products',
        path: '/homeQuiz',
      },
      {
        id: 'profile',
        title: 'Profile',
        icon: <AiOutlineProfile color='#288D9B' />,
        messageId: 'sidebar.profile',
        path: '/profile',
      },
      {
        id: 'user',
        icon: <BiUser color='#288D9B' />,
        title: 'User',
        messageId: 'sidebar.user',
        path: '/user',
      },
      {
        id: 'list-products-quiz',
        icon: <TbReport color='#288D9B' />,
        title: 'listProductsQuiz',
        messageId: 'sidebar.listProductsQuiz',
        path: '/list-products-quiz',
      },
      {
        id: 'history',
        title: 'history',
        icon: <MdOutlineAssignment color='#288D9B' />,
        messageId: 'sidebar.history.admin',
        path: '/history',
      },
      {
        id: 'roles',
        title: 'Roles',
        messageId: 'sidebar.roles',
        icon: <AiOutlineRadiusSetting color='#288D9B' />,
        path: '/roles',
      },
    ],
  },
  {
    id: 'questions',
    title: 'Questions',
    messageId: 'sidebar.question',
    type: 'group',
    role: 'QUESTIONS',
    children: [
      {
        id: 'all',
        title: 'AllQuiz',
        icon: <BiListOl color='#288D9B' />,
        messageId: 'sidebar.allQuiz',
        path: '/questions/all',
      },
      {
        id: 'myquestions',
        icon: <MdOutlinePersonSearch color='#288D9B' />,
        title: 'User',
        messageId: 'sidebar.myQuiz',
        path: '/questions/myquestions',
      },
    ],
  },
  {
    id: 'feature',
    icon: <BiAperture color='#288D9B' />,
    title: 'Feature',
    messageId: 'sidebar.feature',
    type: 'collapse',
    role: 'FEATURES',
    children: [
      {
        id: 'service-manage',
        icon: <BiCard color='#288D9B' />,
        title: 'ServiceManage',
        messageId: 'sidebar.serviceManage',
        path: '/service-manage',
      },
      {
        id: 'add',
        icon: <BiMessageSquareAdd color='#288D9B' />,
        title: 'AddQuiz',
        messageId: 'sidebar.addQuiz',
        path: '/questions/add',
      },
    ],
  },
  // {
  //   id: 'history',
  //   title: 'History',
  //   messageId: 'sidebar.history',
  //   icon: <IoIosTimer color='#288D9B' />,
  //   type: 'collapse',
  //   role: 'HISTORY',
  //   children: [
  // {
  //   id: 'report',
  //   title: 'history report',
  //   icon: <TbReport color='#288D9B' />,
  //   messageId: 'sidebar.history.report',
  //   path: '/history/report',
  // },
  // {
  //   id: 'list-history-assignments',
  //   title: 'history assignment',
  //   icon: <MdOutlineAssignment color='#288D9B' />,
  //   messageId: 'sidebar.history.assignment',
  //   path: '/history/list-history-assignments',
  // },

  // ],
  // },
];
export default sidebarConfig;
